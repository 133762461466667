<template>
  <v-app :dark="colorScheme.dark" :class="colorScheme.scheme">
    <notification />
    <router-view name="header" />
    <router-view name="sidebar" />
    <v-content>
      <router-view />
    </v-content>
    <router-view name="footer" />
  </v-app>
</template>
<script>
import { mapGetters, mapMutations } from "vuex"
import { getUserInfo, logoutRequest } from "./api/auth"
import { types } from "./store/modules/user"
import { log } from "./utils/helpers"
import { setAccessToken } from "./utils/axios"
import { resetAccessToken } from "./utils/axios"
import Notification from "./components/notifications/Notification"
export default {
  components: { Notification },
  data() {
    return {
      subDrawer: false
    }
  },
  computed: {
    ...mapGetters({
      colorScheme: "colorScheme"
    })
  },
  created() {
    try {
      this.init()
      this.getAboutUserInfo()
    } catch (e) {
      log(e)
      if (this.$route.name !== 'login') {
        return this.$router.push({ name: "login" })
      }
    }
  },
  methods: {
    ...mapMutations({
      setUser: types.SET_AUTH_USER,
      setUserInfo: types.SET_USER_INFO
      // setSalon: salonTypes.SET_ACTIVE_SALON,
      // setHandleState: salonTypes.SET_HANDLE_STATE
    }),
    // ...mapActions(["getReasonsSettings"]),
    init() {
      // set user from local storage
      const cachedUser = this.$ls.get("user")

      if (!cachedUser) {
        throw new Error("No user to parse")
      }

      this.setUser(cachedUser)

      const cachedToken = this.$ls.get("access_token")

      if (!cachedToken) {
        throw new Error("Unauthorized")
      }

      setAccessToken("Bearer", cachedToken)

      const cachedRole = this.$ls.get("user").role

      if (!this.$route.fullPath.includes('/parser/') && cachedRole === 'Editor') {
        this.$router.push({ name: "parser_need_approve_posts", params: { name: 'telegram' } })
      }

    },
    async getAboutUserInfo() {
      await getUserInfo()
        .then(response => {
          if (response.data.data.role === "User") {
            this.onLogout()
          } else {
            this.setUserInfo(response.data.data)
          }
        })
        .catch(() => {
          this.populateErrorBags()
        })
    },
    removeToken() {
      this.$ls.set("user", "")
      this.$ls.set("access_token", "")
      resetAccessToken()
    },
    onLogout() {
      logoutRequest()
        .then(() => {
          this.$notificationShow("You have been logged out")
          this.removeToken()
          this.$router.push({ name: "login" })
        })
        .catch(this.$notificationHandleResponseError)
    }
  },
}
</script>

<style>
.v-content__wrap {
  /*padding-bottom: 75px;*/
}
</style>
