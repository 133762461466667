export default {
  header: "Tab",
  headerText:
    "Компонент `v-tabs` используется для скрытия содержимого за выбранным элементом. Это также можно использовать в качестве псевдо-навигации для страницы, где вкладки являются ссылками, а вкладками являются содержимое.",
  components: [
    "v-tabs",
    "v-tab",
    "v-tabs-items",
    "v-tab-item",
    "v-tabs-slider"
  ],
  examples: [
    {
      toolbar: {
        header: "Toolbar tabs",
        desc: ""
      },
      fixedTabs: {
        header: "Fixed tabs",
        desc:
          "**fixed-tabs** prop устанавливает более высокую минимальную ширину и применяет новую максимальную ширину к `v-tabs-items`. На desktop screens элементы вкладки будут центрироваться в компоненте `v-tabs` и переключаться на равномерное заполнение на мобильном устройстве."
      },
      right: {
        header: "Выровненные справа tabs",
        desc: "**right** prop выравнивает tabs в право"
      },
      content: {
        header: "Контент",
        desc:
          "Вкладки - это не единственное, что можно добавить в компонент `v-tabs`. В этом примере мы также добавили панель инструментов."
      },
      search: {
        header: "С поиском",
        desc:
          "Вот еще один пример дополнительного содержимого внутри компонента `v-tabs`."
      },
      iconsAndText: {
        header: "Иконки и текст",
        desc:
          "Используя **icons-and-text** prop, вы можете добавлять значки к каждому элементу табуляции."
      },
      desktop: {
        header: "Desktop tabs",
        desc: ""
      },
      alignWithTitle: {
        header: "Выровнять вкладки с заголовком панели инструментов",
        desc:
          "Создайте `v-tabs`, выровненные с компонентом `v-toolbar-title` (`v-toolbar-side-icon` или `v-btn` должны использоваться в `v-toolbar`). Может не работать, если текст вкладки завернут."
      },
      grow: {
        header: "Grow",
        desc:
          "**grow** prop сделают элементы вкладки заняты всем доступным пространством."
      },
      overflowToMenu: {
        header: "С меню",
        desc:
          "Вы можете использовать меню, чтобы удерживать дополнительные вкладки, заменяя их на лету"
      },
      pagination: {
        header: "Pagination",
        desc:
          "Если элементы табуляции переполняют свой контейнер, появятся элементы управления разбиением на страницы."
      },
      icons: {
        header: "Пользовательские иконки",
        desc:
          "**prev-icon** и **next-icon** могут использоваться для применения пользовательских значков разбиения на страницы."
      }
    }
  ],
  props: {
    alignWithTitle:
      "Сделайте `v-tabs` выровненным с заголовком панели инструментов",
    prevIcon: "Значок левой страницы",
    nextIcon: "Значок правой страницы",
    right: "Выравнивает вкладки вправо"
  }
}
