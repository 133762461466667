export default {
  props: {
    appendIcon: "使用与`v-icon`相同的语法在组件上添加一个后置图标",
    appendIconCb: "单击回调添加后置图标",
    disabled: "输入框是禁用的",
    hideDetails: "隐藏提示，验证错误",
    hint: "提示文本",
    label: "设置输入框标签",
    persistentHint: "强制提示始终可见",
    placeholder: `设置输入框的占位符文本`,
    prependIcon: "使用与`v-icon`系统的语法在组件上添加一个图标",
    prependIconCb: "单击时回调添加前置图标",
    readonly: "将输入之余只读状态",
    required: "根据需要指定输入框，将星号添加到标签的末尾，不执行任何验证。",
    tabindex: "Tabindex of input",
    toggleKeys: "将切换输入框（如果它支持切换）的键码数组。",
    value: "输入值"
  }
}
