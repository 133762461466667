export default {
  advanced: "Advanced",
  application: "Application",
  beginner: "Beginner",
  desktop: "Desktop",
  experience: "Experience",
  intermediate: "Intermediate",
  mobile: "Mobile",
  no: "No",
  question1: "How experienced are you with Vue?",
  question2: "What platform are you developing for?",
  question3: "Does your project require test scaffolding?",
  question4: "Does your application require SEO optimization?",
  reset: "Reset",
  seo: "SEO",
  suggestion: "Suggestion",
  tests: "Tests",
  question5:
    "If there is a recommendation for your selections it will be highlighted below",
  yes: "Yes",
  web: "Web"
}
