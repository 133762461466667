export default {
  header: "Free and Premium themes",
  headerText:
    "Vuetify offers both **free** and **premium** pre-made themes designed to get you started in a flash. Free themes are available to install through vue-cli or you can simply download the source.",
  free: "Free",
  demo: "Demo",
  comingSoon: "coming soon",
  sourceCode: "source code",
  templates: [
    {
      screenshot: "/static/doc-images/starter/vuetify-premium.jpg",
      title: "Alpha Theme",
      tag: "Premium",
      description:
        "Complete theme experience including enhanced vue-cli, full documentation, 5 custom components and much more!",
      price: "25$",
      demoUrl: ""
    },
    {
      screenshot: "/static/doc-images/starter/vuetify-parallax-starter.png",
      title: "Parallax",
      description:
        "This beautiful single page parallax is a great home page for any application.",
      sourceUrl: "vuetifyjs/parallax-starter",
      demoUrl: "/themes/parallax-starter"
    },
    {
      screenshot: "/static/doc-images/starter/vuetify-blog-starter.png",
      title: "Blog",
      description:
        "A simple template that features a clean interface for creating a blog or blog-like application.",
      sourceUrl: "vuetifyjs/blog-starter",
      demoUrl: "/themes/blog-starter"
    }
  ]
}
