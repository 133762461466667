export default {
  header: "Parallax",
  headerText:
    "Компонент `v-parallax` создает 3D-эффект, который заставляет изображение прокручиваться медленнее, чем окно.",
  components: ["v-parallax"],
  examples: [
    {
      default: {
        header: "По умолчанию",
        desc:
          "Параллакс вызывает смещение фонового изображения, когда пользователь прокручивает страницу."
      },
      content: {
        header: "С контентом",
        desc:
          "Вы также можете разместить любой контент внутри параллакса. Это позволяет использовать параллакс в качестве главного изображения."
      },
      customHeight: {
        header: "Пользовательская высота",
        desc:
          "Вы можете указать произвольную высоту параллакса. Имейте в виду, что это может сломать параллакс, если ваше изображение не имеет подходящего размера"
      },
      jumbotron: {
        header: "Jumbotron",
        desc:
          "Параллакс может быть отключен для использования в качестве стандартного **jumbotron**"
      }
    }
  ],
  props: {
    alt: "Прикрепляет свойство alt к изображению параллакса",
    src: "Изображение для параллакса"
  }
}
