export default {
  header: "Кнопки: плавающая кнопка действия",
  headerText:
    "`v-btn`компонент может использоваться как плавающая кнопка действия. Это обеспечивает приложение главной точкой действия. В сочетании с компонентом `v-speed-dial` вы можете создать разнообразный набор функций, доступных для ваших пользователей.",
  components: ["v-speed-dial", "v-btn"],
  examples: [
    {
      promotedAction: {
        header: "Продвинутое действие",
        desc:
          "Кнопки с плавающим действием могут быть прикреплены к материалу, чтобы обозначить продвигаемое действие в вашем приложении. Размер в большинстве случаев будет использоваться по умолчанию, в то время как `small` вариант может быть использован, чтобы поддерживать схожесть с размером аналогичных элементов."
      },
      small: {
        header: "Малый вариант",
        desc:
          "Для лучшей визуальной привлекательности мы используем небольшую кнопку, чтобы соответствовать нашим аватарам."
      },
      displayAnimation: {
        header: "Отображать анимацию",
        desc:
          "При первом отображении плавающая кнопка действия должна анимироваться на экране. Здесь мы используем `v-fab-transition` с `v-show`. Вы также можете использовать любой пользовательский переход, предоставляемый Vuetify или вашим собственным."
      },
      lateralScreens: {
        header: "Горизонтальный экран",
        desc:
          "При изменении действия по умолчанию вашей кнопки рекомендуется отображать переход для обозначения изменения. Мы делаем это, связывая `key` с частью данных, которая может правильно сообщать об изменении в системе перехода Vue. Хотя вы можете использовать для этого специальный переход, убедитесь, что вы установили `mode` prop для **out-in**."
      },
      speedDial: {
        header: "FAB со скоростным набором",
        desc:
          "Компонент быстрого набора обладает очень надежным интерфейсом для настройки вашего FAB-интерфейса именно так, как вы хотите."
      }
    }
  ],
  props: {
    "v-btn": {
      block: "Расширяет кнопку до 100% доступного пространства",
      depressed: "Удаляет тень кнопки",
      fab: "Делает кнопку круглой",
      flat: "Удаляет цвет фона кнопки",
      icon: "Назначает кнопку в виде значка - круглый и плоский",
      inputValue: "Управляет активным состоянием кнопки",
      large: "Кнопка большого размера",
      loading: "Добавляет анимацию иконок загрузки",
      outline: "Кнопка будет иметь контур",
      round: "Кнопка будет овальной",
      small: "Кнопка малого размера",
      type: "Установите атрибут типа кнопки"
    },
    "v-speed-dial": {
      direction:
        "Направление, в котором будет отображаться содержимое скоростного набора. Возможные значения: `top`, `bottom`, `left`, `right`.",
      openOnHover: ""
    }
  }
}
