export default {
  header: "海拔",
  headerText:
    "海拔辅助器允许您控制沿着**z-轴**的两个曲面之间的相对深度或距离。共有25个海拔层级，您可以使用类`elevation-{n}`去设置一个元素的海拔，其中的n是一个对应海拔层级所用到的介于0-24的整数。",
  toc: [
    {
      text: "介绍",
      href: "introduction"
    },
    {
      text: "实战场",
      href: "playground"
    }
  ],
  playgroundHeader: "实战场"
}
