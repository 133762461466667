export default {
  header: "Нижний лист",
  headerText:
    "Нижний лист - это измененный `v-dialog`, который скользит с низа экрана, подобно `v-bottom-nav`. В то время как компонент `v-bottom-nav` предназначен для кнопок и конкретных действий уровня приложения, нижний лист может содержать что угодно.",
  components: ["v-bottom-sheet"],
  examples: [
    {
      standard: {
        header: "Стандартный дисплей",
        desc:
          "Здесь мы показываем пример списка действий, которые могут присутствовать в приложении."
      },
      inset: {
        header: "Вставные нижние листы",
        desc:
          "Нижние листы могут быть вставлены, уменьшая максимальную ширину на рабочем столе до 70%. Это может быть дополнительно уменьшено вручную с помощью свойства `width`. Мы также демонстрируем привязку динамического класса с использованием объекта точки останова Vuetify."
      }
    }
  ],
  props: {
    disabled: "Отключает возможность открытия диалога",
    hideOverlay: "Скрыть показ наложения",
    inset: "Уменьшает максимальную ширину содержимого диалогового окна до 70%",
    lazy: "Mixins.Bootable.props.lazy",
    maxWidth: "Укажите максимальную ширину листа контейнера"
  }
}
