export default {
  header: "В чем отличия?",
  headerText: "",
  toc: [
    {
      text: "В чем отличия?",
      href: "why-vuetify"
    },
    {
      text: "Принципы дизайна",
      href: "design-principles"
    },
    {
      text: "Яркое сообщество",
      href: "vibrant-community"
    },
    {
      text: "сравнение framework",
      href: "framework-comparison"
    }
  ],
  philosophies: [
    {
      img: "/static/vuetify_semantic.svg",
      title: "Семантический инструментарий",
      caption:
        "Vuetify строится с нуля до семантического ядра. Простота в освоении, простота запоминания и т. д. Это означает, что независимо от того, разрабатываете ли вы собственное приложение или адаптируете под нового разработчика, компоненты и свойства Vuetify _пишите как вы говорите_ делают разработку легкой."
    },
    {
      img: "/static/vuetify_sizes.svg",
      title: "Непрерывные обновления",
      caption:
        "Vuetify имеет последовательный цикл обновления, позволяющий чаще выполнять исправления ошибок и улучшения. Команда разработчиков стремится предоставить вам лучшую разработку, который у вас есть."
    },
    {
      img: "/static/vuetify_cli.svg",
      title: "Удивительная поддержка",
      caption:
        'Когда у вас возникает проблема, вам необходима помощь немедленно. Vuetify предлагает поддержку чата в нашем растущем сообществе <a href="https://discordapp.com/invite/s93b7Fv" target="_blank" rel="noopener"> Discord </a>.'
    }
  ],
  whyText: [
    "Выбор структуры может быть сложной задачей, и я даже не говорю о компонентах. Вы прибыли во Vue, и теперь пришло время начать творить следующую замечательную идею. Есть много вариантов выбора, и все говорят, что они великолепны. Итак, вы должны решить, какие мои шансы на успех?",
    "Я почти год назад. Было много **наборов инструментов UI** на выбор, но ни у кого из них не было всего, что мне было нужно... да, вы уже это слышали. Часто этого не достаточно, и это - причина личных попыток создать продукты, которые заполняют пробелы, и именно поэтому я начал работать над Vuetify.",
    "Но теперь вы ищете идеальную инфраструктуру, а Vuetify - это еще один список многих, соперничающих за ваше внимание. Хотя мое мнение будет субъективным, я попытаюсь описать функции, которые, я считаю, будут ценными для вас и/или вашей организации."
  ],
  designHeader: "Принципы дизайна",
  designText:
    "Vuetify разработан точно в соответствии со спецификацией Material Design. Каждый компонент разработан вручную, чтобы предоставить вам самые лучшие инструменты пользовательского интерфейса для вашего следующего отличного приложения. Разработка не останавливается на основных компонентах, описанных в спецификации Google. Благодаря поддержке членов сообщества и спонсоров, дополнительные компоненты будут разработаны и доступны для всех.",
  communityHeader: "Яркое сообщество",
  communityText:
    'Разработка с помощью Vuetify означает, что вы никогда не будете одиноки. Немедленно получите помощь от команды разработчиков и других членов сообщества на официальном сервере <a href="https://discordapp.com/invite/s93b7Fv" target="_blank" rel="noopener"> Discord </a>. Получите возможность поговорить с талантливыми людьми, которые хотят, чтобы вы преуспели в своих усилиях. Нужна ли премиальная поддержка? Станьте <a href="https://www.patreon.com/vuetify" target="_blank" rel="noopener"> сторонником Patreon </a> и получите доступ к каналам поддержки #patron или #elite-patron ,',
  comparisonHeader: "Сравнение с другими платформами",
  comparisonText:
    "Vuetify - это не единственная инфраструктура Vue. Множество других талантливых разработчиков создали некоторые удивительные приложения под видом спецификации Google Material Design. Однако каждый из них предлагает свой собственный уникальный подход к разработке и процессу, вот несколько вещей, которые устанавливают Vuetify отдельно:",
  featuresHeader: "То, что вы получаете:",
  featuresList: [
    "Компоненты семантического помощника",
    "поддержка SSR и PWA",
    "8 Vue-CLI шаблонов",
    "Поддержка чата",
    "Модульная система перехода компонентов",
    "Blazing Fast Framework",
    "Компоненты La Carte"
  ],
  letterFromAuthor:
    "Я очень рад, что вы рассмотрели использование Vuetify в качестве следующей платформы пользовательского интерфейса. Много внимания уделило созданию идеальной установки для принятия любых идей от концепции к производству. Я с нетерпением жду, чтобы вы присоединились к сообществу Vuetify, и я очень рад видеть, какие невероятные интерфейсы вы создаете!",
  authorOfVuetify: "Автор Vuetify",
  contactMe: "Свяжитесь со мной",
  questionHeader: "Все еще есть вопросы?",
  questionButton: "Спросите сообщество"
}
