export default {
  header: "成为更大的事物的一部分!",
  headerText:
    "Vuetify正因为由一个惊人的社区提出的问题、创建的拉取请求和提供宝贵的意见才成为可能。使您能够创造惊人的应用程序是我们的工作。很多时候，你会遇到一些可以变得更好的东西。也许你会发现一个错误，或者你有一个额外的功能的想法，那太棒了！开始工作在开发环境将会像克隆Vuetify仓库一样简单。",
  issuesHeader: "提交工单（issues）",
  issue1:
    "仓库的工单列表是专门用来提交Bug报告和特性请求的。其它无关紧要的问题将会被立即关闭。",
  issue2: "一般性问题,请加入[社区](https://chat.vuetifyjs.com)。",
  issue3: "尝试搜索您的工单，它或许已获得回答。",
  issue4: "看看错误是否可用最新版本重现。",
  issue5:
    "如果可重现，请提供一个简单的[codepen示例](https://template.vuetifyjs.com)或者是可被克隆并产生预期行为的仓库。",
  pullRequestsHeader: "拉取请求（Pull requests）",
  pullRequest1: "所有拉取请求都必须放在Vuetify的`dev`分支。",
  pullRequest2:
    "有关更改和功能请求，请包含您正在尝试解决的问题的示例和标签示例。",
  pullRequest3: "有关问题修复，请关联PR所解决的issue #",
  localDevHeader: "安装本地开发",
  localDevText1:
    "Vuetify包含一个本地开发环境，您可以启动以测试新特性、功能和组件。首先，克隆仓库",
  localDevText2:
    "克隆之后，使用你的命令行提示符<kbd>cd vuetify</kbd>。一旦进入目录，您将需要在 <kbd>dev</kbd> 目录中创建一个名为`Playground.vue`新文件。已经用你可以重命名的`Playground.example.vue`提供了一个例子，请记住，该文件不受版本控制的跟踪。一旦被复制，回到你的命令行提示符并运行：",
  localDevText3:
    "它将启动一个定位在[http://localhost:8080](http://localhost:8080)的本地node服务。现在可通过您的代码编辑器轻松地加载组件并以闪电般的速度测试任何您想要的内容！"
}
