export default {
  announcementBanner: "嗨！这是一则令人兴奋的公告，也或许只是一则广告！",
  heading1: "Vuetify",
  heading1cont: "Material Design 组件框架",
  getStarted: "快速入门",
  getHelp: "帮帮我",
  features: [
    {
      img: "/static/doc-images/feature1.svg",
      title: "开箱即用的项目脚手架",
      text:
        "Vuetify已预先制作了8个vue-cli模板。从简单的HTML到全面的服务端渲染（SSR）项目，都只需几分钟就能创建完毕。"
    },
    {
      img: "/static/doc-images/feature2.svg",
      title: "语义化的Material组件",
      text:
        "已为你需要在项目中使用的很多专用组件做好准备。一共拥有80多个组件，是一个适用于任何应用的解决方案。"
    },
    {
      img: "/static/doc-images/feature3.svg",
      title: "充满活力的社区",
      text:
        "当遇到问题时，您可以立即向我们请求协助。Vuetify借助Discord服务成立了正在不断壮大社区，为您提供即时聊天支持。"
    }
  ],
  checkFeaturesTitle: "你需要的工具应有尽有",
  checkFeaturesTitleCtd: "构建令人难以置信的用户界面",
  checkFeatures: ["社区聊天支持", "动态主题", "支持SSR和PWA", "8个vue-cli模版"],
  checkFeaturesCtd: [
    "80+ Material Design组件",
    "可单个导入",
    "过渡组件",
    "快速构建"
  ],
  letterFromAuthor: [
    "大家好,",
    "我知道选择一个UI框架可能是一项艰巨的任务——有太多的选择，而且他们中的每一个都声称它们是最好的。所以你必须自己决定：成功的标准是什么？",
    "在差不多一年前，我在众多可供选择的UI工具包中，却没有找到一个能够满足我需要的一切，这就是为什么我开始研究Vuetify的原因。",
    "你已经考虑使用Vuetify作为你的下一个UI框架，这让我极为谦卑。我在制定为任何想法所采取的从概念到生产的完美设置的过程中付出了很多心血。我期待着您加入Vuetify社区，并且很高兴看到您创建了多么令人难以置信的界面！"
  ],
  authorOfVuetify: "Vuetify的作者",
  support: {
    hasVuetifyHelped: "Vuetify是否已经帮助您创建了一个惊艳的应用程序？",
    showYourSupport: "如果想表达对Vuetify的支持，您可以",
    becomingAPatron: "成为赞助人"
  },
  becomeSponsor: "成为赞助商",
  proudlySponsoredBy: "赞助商",
  callout: "让Vue和Material Design的强大力量在开发上助您一臂之力"
}
