import { removalDetails } from "../../api/removalDetails"
const state = {
  removalInfo: {}
}
const getters = {
  getRemovalInfo: state => {
    return state.removalInfo
  }
}
 
const mutations = {
  MUT_REMOVAL_INFO: (state, value) => {
    state.removalInfo = value
  }
}
const actions = {
  getRemovalDetails({ commit }, id) {
    removalDetails(id)
      .then(response => {
        commit("MUT_REMOVAL_INFO", response.data.data)
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
