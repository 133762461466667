export default {
  header: "Toolbar",
  headerText:
    "Компонент `v-toolbar` является ключевым для любого gui, поскольку он обычно является основным источником навигации по сайту. Компонент панели инструментов отлично работает в сочетании с панелью навигации для скрытия ссылок и представления активатора для открытия боковой панели на мобильном устройстве.",
  components: ["v-toolbar", "v-system-bar"],
  examples: [
    {
      usage: {
        header: "Использование",
        desc:
          "Панель инструментов представляет собой гибкий контейнер, который можно использовать несколькими способами. По умолчанию панель инструментов имеет 64px на десктопе, 56px на мобиле в портретном режиме и 48px на мобиле в ландшафте. Существует ряд вспомогательных компонентов, доступных для использования с панелью инструментов. Это `v-toolbar-side-icon`, `v-toolbar-title` и `v-toolbar-items`."
      },
      appBar: {
        header: "Панель приложений",
        desc:
          "Панель приложений является основной панелью приложения. Он может содержать значки, меню и многое другое."
      },
      appBarItems: {
        header: "Панель приложений с элементами",
        desc:
          "Используя компонент `v-toolbar-items`, вы можете использовать регулярные компоненты` v-btn` для создания элементов панели приложения. Не забывайте применять «плоскую» опору к каждой кнопке.Используя компонент `v-toolbar-items`, вы можете использовать регулярные компоненты `v-btn` для создания элементов панели приложения. Не забывайте применять `flat` prop к каждой кнопке."
      },
      appBarExtension: {
        header: "Панель приложений с расширением",
        desc:
          "Можно использовать расширение, которое создает гораздо более заметную панель. Если заголовок помещен в расширение, он будет автоматически смещен вправо, чтобы соответствовать нормальному расположению, на котором он находился бы в основной строке."
      },
      columnWidthWithHierarchy: {
        header: "Column width with hierarchy",
        desc:
          "Панели инструментов представлены в двух вариантах: светлых и темных. Светлые панели инструментов имеют темные тонированные кнопки и темный текст, тогда как темные панели имеют белые тонированные кнопки и белый текст."
      },
      flexibleAndCard: {
        header: "Flexible toolbar and card toolbar",
        desc:
          "С помощью нескольких пользовательских стилей вы можете легко создавать свои удивительные пользовательские интерфейсы."
      },
      floatingWithSearch: {
        header: "Floating with search",
        desc:
          "Плавающая панель инструментов может быть размещена над содержимым, на которое оно ссылается."
      },
      variations: {
        header: "Variations",
        desc:
          "Панель приложений имеет несколько вариантов, которые могут применяться с темами и вспомогательными классами. Они варьируются от светлых и темных тем, цветных и прозрачных.",
        uninverted: true
      },
      prominent: {
        header: "Prominent toolbars",
        desc:
          "Prominent панели инструментов добавляют слот, в который вы можете поместить элементы в расширенную область. Области содержимого и расширения будут заблокированы до 64px в высоту."
      },
      dense: {
        header: "Dense toolbars",
        desc:
          "Dense панели инструментов уменьшают их высоту до 48px. Это также поведение по умолчанию для мобильной ландшафтной ориентации."
      },
      denseProminent: {
        header: "Dense prominent toolbars",
        desc:
          "Вы можете комбинировать как prominent, так и dense, чтобы расширить малый вариант панели инструментов."
      },
      scrolling: {
        header: "Методы прокрутки",
        desc:
          "При прокрутке панели инструментов можно перейти с экрана. Здесь мы демонстрируем, как вы можете указать цель прокрутки (по умолчанию это окно). Имейте в виду, для этого примера есть специальная разметка, которая не потребуется в вашем приложении."
      },
      appBarMenu: {
        header: "App bar with menu",
        desc: "Вы можете добавлять компоненты меню на панель инструментов."
      },
      systemBarsStatusWindow: {
        header: "System bars - status/window",
        desc:
          "Системные панели полезны для иконок уведомлений на мобильных устройствах и на десктопах. Для живых примеров, перейдите к заранее определенным макетам."
      }
    }
  ],
  props: {
    card: "Наследует радиус границы при использовании внутри `v-card`",
    clippedLeft:
      "Указывает, что приложения `v-navigation-drawer` обрезаются с левой стороны панели инструментов",
    clippedRight:
      "Указывает, что приложения `v-navigation-drawer` обрезаются с правой стороны панели инструментов",
    dense: "Уменьшает высоту содержимого панели инструментов и расширения",
    extended:
      "Настройте панель инструментов для создания расширения без использования слота",
    flat: "Удаляет тень панели инструментов",
    floating: "Создает встроенную панель инструментов",
    height: "Определяет определенную высоту для панели инструментов",
    lightsOut: "Уменьшает непрозрачность панели",
    manualScroll: "Вручную применить функциональность **scroll-off-screen**",
    prominent:
      "Увеличивает высоту содержимого панели инструментов и расширения",
    scrollOffScreen: "При перемещении панели инструментов и расширении экрана",
    scrollToolbarOffScreen:
      "Если вы прокрутите вниз, перейдет панель инструментов",
    scrollTarget: "Назначить цель прокрутки для прокрутки экрана",
    scrollThreshold:
      "Количество прокрутки вниз до панели инструментов использует прокрутку за кадром",
    status: "Уменьшает высоту панели",
    window: "Увеличивает высоту панели"
  }
}
