import api from "./index"

/**
 * Login user
 *
 * @return {*}
 * @param data 
 */
export const loginRequest = data => {
  return api
    .request("admin_login")
    .withBody(data)
    .post()
}
/**
 * Get user info
 *
 * @return {*}
 * @param data
 */
export const getUserInfo = () => {
  return api.request("getUserInfo").get()
}
/**
 * Logout user
 *
 * @return {*}
 */
export const logoutRequest = () => {
  return api.request("logout").post()
}
