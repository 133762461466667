export default {
  header: "Подсказка",
  headerText:
    "Компонент `v-tooltip` полезен для передачи информации, когда пользователь нависает над элементом. Вы также можете программно управлять отображением всплывающих подсказок с помощью **v-model**",
  components: ["v-tooltip"],
  examples: [
    {
      default: {
        header: "По умолчанию",
        desc: "Подсказки могут обернуть любой элемент."
      },
      alignment: {
        header: "Выравнивание",
        desc:
          "Подсказка может быть выровнена с любой из четырех сторон элемента-активатора"
      },
      visibility: {
        header: "Видимость",
        desc:
          "Видимость подсказки можно программно изменить, используя `v-model`."
      }
    }
  ],
  props: {
    closeDelay:
      "Задержка (в миллисекундах), после которой закрывается меню (когда для параметра **open-on-hover** установлено значение **true**)",
    debounce:
      "Длительность перед всплывающей подсказкой отображается и скрывается при наведении курсора",
    openDelay:
      "Задержка (в миллисекундах), после которой открывается меню (когда для параметра **open-on-hover** установлено значение **true**)"
  }
}
