export default {
  props: {
    actions: "Используйте это при использовании действий внутри picker",
    autosave:
      "Автоматически сохранять выбранное значение. Это обновляет внутреннее прошлое значение, поэтому, отмена не вернет изменения пользователя",
    headerColor:
      "Определяет цвет заголовка. Если он не указан, он будет использовать цвет, определяемый свойством `color` или цветом по умолчанию",
    landscape: "Ориентировать picker по горизонтали",
    noTitle: "Скрыть у picker заголовок",
    scrollable: "Позволяет использовать колесико мыши в picker",
    value: "Значение picker "
  }
}
