export default {
  props: {
    activator:
      "在未使用激活器插槽激活器4.字符串可以是任何有效的查询选择器（querySelector），对象（Object）可以是任何有效的Node对象。",
    allowOverflow: "移除内容的移除重新定位",
    maxWidth: `设置内容的最大宽度`,
    minWidth: `设置内容的最小宽度`,
    nudgeBottom: "向底部微调内容",
    nudgeLeft: "向左侧微调内容",
    nudgeRight: "向右侧微调内容",
    nudgeTop: "向顶部微调内容",
    nudgeWidth: "微调内容宽度",
    offsetOverflow: "当由于溢出而重新定位时，导致组件翻转到另一侧",
    positionX: "用于在不使用激活器插槽时定位内容",
    positionY: "用于在不使用激活器插槽时定位内容",
    zIndex: "用于组件的z-index"
  }
}
