export default {
  close: "닫기",
  init: "Init command copied",
  quickstart: {
    title: "One Minute Quickstart",
    desc:
      "Useful for quickly previewing the capabilities of the Vuetify framework. Because Vuetify can be bound at run-time. Even in environments with no build process, you can create beautiful model bound templates."
  },
  simple: {
    title: "Simple HTML",
    desc:
      "This template is intended for users who want to try out Vue.js and Vuetify.js in the most simplistic way. It contains a basic index.html with no additional functionality. This is useful for developers who want to easily preview the features of the framework."
  },
  webpack: {
    title: "Webpack",
    desc:
      "This template is intended for users who are looking for out of the box linting and unit testing."
  },
  webpackSimple: {
    title: "Webpack Simple",
    desc:
      "This template is intended for users who are already familiar with Vue/Webpack. It contains a very simple webpack setup and is targetted at developers creating prototype or basic applications."
  },
  webpackSSR: {
    title: "Webpack SSR",
    desc:
      'This template is for advanced users looking to utilize the new Vue SSR (server-side rendering). Based off of the structure in the Vue.js 2 <a class="white--text" href="https://github.com/vuejs/vue-hackernews-2.0" target="_blank" rel="noopener">Hackernews</a> repository. The Vuetify.js SSR template provides next generation functionality for advanced Vue applications.'
  },
  nuxt: {
    title: "NUXT",
    desc:
      "Utilizing the power of NUXT, supercharge your development experience with a bootstraped version ready to go with Vuetify out of the box."
  },
  pwa: {
    title: "PWA",
    desc:
      "A pre-configured PWA (Progressive Web Application) template is at your disposal. Bootstraped with service workers, application manifest, and a 90+/100 Lighthouse score."
  },
  electron: {
    title: "Electron",
    desc:
      "Vuetify's official Electron template for creating desktop applications."
  },
  aLaCarte: {
    title: "A La Carte",
    desc:
      "In this template you can see an example of how to select only the components you want to use. This is useful for reducing package size with unused components."
  },
  cordova: {
    title: "Cordova",
    desc:
      "This template uses Apache Cordova to help you easily convert your web app into a native Android or iOS app."
  }
}
