import { getAllUsersRequest } from "../../api/users"
import { fetchData } from "../response"

/**
 * Vee validate email exists validator
 */
export class EmailUniqueValidator {
  getMessage(field) {
    return `This ${field} has already been taken`
  } 

  async validate(value) {
    const collection = await getAllUsersRequest({
      search: `email:${value}`
    }).then(fetchData)

    return collection && collection.length <= 0
  }
}
