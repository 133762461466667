import api from "./index"

/**
 * Get all Countries
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getAllCountries = (params, includes = []) => {
    return api
        .request("countries?locale_language=en")
        .withParams(params)
        .withIncludes(includes)
        .get()
}