export default {
  props: {
    customFilter: "Фильтр пользовательского поиска",
    customSort: "Пользовательский сортировочный фильтр",
    disableInitialSort:
      "Отключить сортировку по умолчанию при первоначальном рендеринге",
    expand:
      "Обозначает таблицу как содержащую строки, которые могут расширяться",
    filter: "Функция, используемая для фильтрации элементов",
    hideActions: "Скрыть действия таблицы",
    itemKey: "Поле в элементе объекта, которое обозначает уникальный ключ",
    items: "Массив строк таблицы",
    mustSort:
      "Заставляет по меньшей мере один столбец всегда сортироваться вместо переключения между **отсортированным по возрастанию** / **отсортированным нисходящим** / **несортированным** состоянием",
    noResultsText: "Отображать текст, если нет отфильтрованных результатов",
    pagination:
      "Used to control pagination and sorting from outside the data table. Can also be used to set default sorted column",
    rowsPerPageItems:
      "Значения по умолчанию для раскрывающегося списка строк на странице",
    rowsPerPageText: "Строки по умолчанию для каждой страницы",
    search: "Модель поиска для фильтрации результатов",
    selectAll:
      "Добавляет флажок в строке заголовка. Может быть либо String, который указывает, какой цвет применяется к кнопке, либо логический (который использует цвет по умолчанию)",
    totalItems:
      "Вручную задает общее количество элементов строки, что отключает встроенную сортировку и разбиение на страницы. Используется вместе с prop для разбивки на страницы, чтобы разрешить сортировку и разбиение на страницы на стороне сервера",
    value: "Выбранные элементы строки"
  },
  slots: {
    footer: "Слот для указания дополнительного footer",
    noData:
      "Отображается, когда нет элементов (имеет преимущество перед `no-results`)",
    noResults: "Отображается, когда нет фильтрованных элементов"
  },
  scopedSlots: {
    items: "Слот для определения способа отображения элементов",
    pageText:
      "Слот для настройки области текста страницы элементов управления разбиением на страницы."
  }
}
