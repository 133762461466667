export default {
  indexHeader: "Добро пожаловать в наш новый магазин!",
  indexSubheader: "Повысьте свою эффективность",
  cartHeader: "Ваша корзина",
  cartSubheader: "Вы почти закончили!",
  cartEmptySubheader: "Ваша корзина на данный момент пуста",
  thankYouHeader: "Спасибо!",
  thankYouSubheader:
    "Ваша покупка помогает поддерживать постоянное обслуживание Vuetify",
  helpSupport:
    "Помогите поддержать текущую разработку Vuetify и получить спец поддержку себе и своей команде!",
  checkout: "Перейти к оформлению заказа",
  addToCart: "добавить в корзину",
  details: "Детали",
  description: "Описание",
  back: "назад",
  backToStore: "Продолжить покупки",
  thankYouBack:
    "Проблемы? <br> Приходите в [сообщество](https://community.vuetifyjs.com)",
  onSale: "В продаже",
  cartHeaders: [
    {
      text: "Продукт",
      value: false,
      sortable: false
    },
    {
      text: "Имя",
      value: false,
      sortable: false
    },
    {
      text: "Цена",
      align: "right",
      value: false,
      sortable: false
    },
    {
      text: "Количество",
      align: "right",
      value: false,
      sortable: false,
      width: "50px"
    }
  ]
}
