export default {
  props: {
    absolute: "Позиционировать элемент абсолютно",
    bottom: "Выровнять компонент по нижнему краю",
    fixed: "Установите фиксированный элемент",
    left: "Выровнять компонент слева",
    right: "Выровнять компонент справа",
    top: "Выровнять компонент в по верхнему краю"
  }
}
