<template>
  <v-img
    :src="backgroundImg"
    class="page-vimg"
  >
    <toolbar-simple />
    <v-container fill-height pa-0>
      <v-layout align-center>
        <v-flex xs12>
          <v-card 
            class="text-xs-center margin-auto border-radius6 box-pink-500-glow elevation-10 card-center-box"
          >
            <v-img
              :src="backgroundImg"
            >
              <v-layout align-center justify-center row fill-height>
                <v-flex xs12>
                  <div class="display-4 white--text" v-text="title"></div>
                  <p class="subheading white--text" v-html="description"></p>
                  <v-btn :to="{ name: 'dashboard/Dashboardv1' }" round color="primary" dark>
                    Go Home
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-img>
</template>

<script>
import ToolbarSimple from "@/layouts/FullPageSimple/Toolbar"
export default {
  components: {
    ToolbarSimple
  },
  data() {
    return {
      title: "404",
      description: `Sorry but we could not find the page <br>you are looking for`
    }
  },
  computed: {
    backgroundImg() {
      return "/static/doc-images/HexesisMaterial02.png"
    }
  }
}
</script>
