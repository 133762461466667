export default {
  header: "Содержание",
  headerText:
    "Vuetify имеет индивидуальный стиль для нескольких стандартных элементов.",
  blockquoteHeader: "Цитата из блока",
  paragraphsHeader: "Параграф",
  codeHeader: "Код",
  codeText: "Пример встроенного элемента `<code>`.",
  variablesHeader: "Переменные",
  variablesText: "<var>v</var> = <var>u</var> * <var>e</var>",
  userInputHeader: "Пользовательский ввод",
  userInputText:
    "Чтобы установить Vuetify, введите <kbd> npm install vuetify </kbd> в консоль. После завершения введите <kbd> cd `<имя проекта>` </kbd> и запустите <kbd> npm install </kbd>"
}
