import { isUndefinedOrNull } from "../helpers"
import moment from "moment"

export class DateIsAfterValidator {
  getMessage(field) {
    return `The ${field} is invalid`
  }

  validate(value, [targetValue]) {
    if (isUndefinedOrNull(targetValue)) {
      return true
    } 

    return moment(value).isAfter(moment(targetValue))
  }
}
