export default {
  header: "고도(Elevation)",
  headerText:
    "고도(Elevation) 헬퍼로 두 요소의 **z-axis** 따른 상대적 깊이,거리를 조정할 수 있습니다. 총 25 단계의 고도 레벨이 있습니다. `elevation-{n}` 클래스는 요소의 높이를 결정합니다. `n`은 고도를 나타내는 0-24 사이의 정수입니다.",
  toc: [
    {
      text: "예제",
      href: "introduction"
    },
    {
      text: "Playground",
      href: "playground"
    }
  ],
  playgroundHeader: "Playground"
}
