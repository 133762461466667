import { fetchData } from "../../utils/response"
import { getAllCountries } from "../../api/countries"
const state = {
    countries: []
}

const getters = {
    countries: state => {
        return state.countries
    }
}

export const types = {
    SET_COUNTRIES: "SET_COUNTRIES"
}

const mutations = {
    [types.SET_COUNTRIES](state, data) {
        state.countries = data
    }
}

const actions = {
    getCountries({ commit }) {
        getAllCountries()
            .then(fetchData)
            .then(data => commit(types.SET_COUNTRIES, data))
    }
}

export default {
    state,
    actions,
    getters,
    mutations
}
