export default {
  Any: "Any",
  Array: "배열",
  Boolean: "부울",
  Function: "함수",
  Number: "숫자",
  Object: "객체",
  String: "문자열",
  undefined: "undefined"
}
