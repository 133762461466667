export default {
  header: "Обзор",
  headerText:
    "Этот обзор призван помочь вам свободно владеть шаблоном **Vuetify** Vue SSR. Если вы еще не установили шаблон `webpack-ssr`, сделайте это [в Быстрый старт](/vuetify/quick-start).",
  structureHeader: "Structure",
  structureText1:
    "Этот шаблон Vue CLI был разработан для повышения производительности, оптимизации SEO и удобства использования. Этот шаблон настроен из коробки для препроцессоров css, sass и stylus.",
  structureText2:
    "Папка **Build** содержит все специфические конфигурации для веб-пакета для вашего проекта. **Src** - это место, где находятся все файлы проекта разработки. Обратите внимание, что шаблон Webpack-SSR настроен на использование [Vue Router](https://router.vuejs.org/en/), [Vuex](https://vuex.vuejs.org/en/intro.html) и [Vue Server Renderer](https://vuejs.org/v2/guide/ssr.html). Это позволит вам создавать простые или сложные приложения, которые не только быстрые / эффективные, но и **SEO** дружественные.",
  structureSubHeader: "Структура папок",
  applicationHeader: "Приложения",
  applicationText1:
    "Перейдите в папку **src** и откройте `App.vue`. Vuetify - это семантическая структура. Код, который вы пишете, должен быть легко запоминаемым и простым в управлении. Для этого одним из основных компонентов Vuetify является `v-app`. Этот компонент позволяет вам определить макет вашего приложения. Это используется в сочетании с `v-toolbar`, `v-navigation-drawer` и `v-footer`.",
  applicationText2:
    "Разметка ниже сообщает приложению, что у вас есть `панель инструментов` и `нижний колонтитул`. После определения область содержимого будет изменена для размещения. Для получения дополнительной информации о макетах перейдите к разделу [Layouts](/layouts/pre-defined).",
  applicationText3:
    "Поместите все статически обслуживаемые активы в `static`. К этому можно обратиться, ссылаясь, например, на `/static/picture.png`.",
  applicationText4:
    "В папке компонентов размещаются ваши пользовательские компоненты. Измените файл `components/_index.js`, чтобы эти файлы автоматически загрузились для вас.",
  applicationSubHeader1: "Статические активы",
  applicationSubHeader2: "Компоненты",
  routingHeader: "Mаршрутизация",
  routingText1:
    "Шаблон Webpack-SSR использует официальный Vue Router для управления потоком приложений. Находится в `/src/router/index.js`, здесь будут определены все маршруты вашего приложения и логика маршрута. Внутри вы также увидите функцию, которая позволяет вам настраивать динамически импортированные страницы. Это не необязательно, однако, это поможет разбить ваше приложение и только загружать представления, которые необходимы, уменьшая тем самым файл бандл.",
  routingText2:
    "Доступ к этим маршрутам можно получить, создав ссылку на указанный путь или используя компонент Vue Router `<router-link>`. Для получения дополнительной информации просмотрите официальный Vue Router [документация](https://router.vuejs.org/en/). Функция по умолчанию предполагает, что вы размещаете представления в папке <kbd> /pages </kbd>, содержащей **View** в конце файла. Пример `HomeView.vue`.",
  stateControlHeader: "Контроль состояния",
  stateControlText1:
    "Контроль состояния управляется официальной библиотекой Vuex. Этот плагин Vue соответствует шаблону проектирования Facebook Reflux. Перейдите в `/store/index.js`. По умолчанию Vuex настроен на предварительную выборку данных state до того, как ваша страница будет первоначально отображаться. Чтобы подключиться к этой функции, создайте метод `preFetch` на вашем компоненте вида.",
  stateControlText2:
    "Это полезно для начальной загрузки вашего приложения, чтобы все необходимые данные были доступны до первоначального рендеринга.",
  stateControlText3:
    "Для получения дополнительной информации о State Control и Vuex просмотрите официальную [документацию](https://vuex.vuejs.org/en/intro.html).",
  stateControlText4:
    "Vuetify интегрируется в Vuex, что позволяет легко отлаживать и получать доступ к сохраненным свойствам. Вы также можете использовать наблюдателей для подключения к состояниям хранилища и реагирования при их изменении. Более подробную информацию можно найти [здесь](https://vuejs.org/v2/guide/reactivity.html).",
  metaDataHeader: "Метаданные",
  metaDataText:
    "Чтобы обеспечить просмотр метаданных конкретных страниц при просмотре страниц, вы можете определить информацию о своей странице на странице `router/meta.json`. Это позволит вам установить заголовок страницы, описание и ключевые слова для каждого маршрута. Это будет автоматически применено для вас при загрузке и изменении страницы.",
  redirectsHeader: "Перенаправление",
  redirectsText:
    "По мере развития приложений маршруты меняются. Если вы хотите сохранить полномочия, уже полученные от ботов, которые индексируют вашу страницу, лучше назначить 301 перенаправление на новое место. Находясь в `router/301.json`, вы можете определить 301 переадресацию для своих страниц, которые будут автоматически обрабатываться для вас при загрузке страницы.",
  webAppSupportHeader: "Поддержка веб-приложений",
  webAppSupportText:
    "Vuetify SSR поддерживает собственные веб-приложения на смартфонах. Также известные как прогрессивные веб-приложения, ваши веб-сайты могут быть сохранены на главном экране устройства, что позволяет использовать его в автономном режиме и получать push-уведомления. Для получения дополнительной информации о веб-приложении Manifest, перейдите в [Mozilla Developer Network](https://developer.mozilla.org/en-US/docs/Web/Manifest). Чтобы увидеть живой пример, добавьте документацию Vuetify на рабочий экран вашего мобильного устройства.",
  toc: [
    {
      text: "обзор",
      href: "introduction"
    },
    {
      text: "Структура",
      href: "structure"
    },
    {
      text: "Приложение",
      href: "application"
    },
    {
      text: "Маршрутизация",
      href: "routing"
    },
    {
      text: "Контроль состояния",
      href: "state-control"
    },
    {
      text: "Метаданные",
      href: "meta-data"
    },
    {
      text: "Перенаправление",
      href: "redirects"
    },
    {
      text: "Поддержка веб-приложений",
      href: "web-app-support"
    }
  ]
}
