import { Validator } from "vee-validate"
import { EmailUniqueValidator } from "./EmailUniqueValidator"
import { PhoneUniqueValidator } from "./PhoneUniqueValidator"
import { MaxDurationValidator } from "./MaxDurationValidator"
import { DateIsAfterValidator } from "./DateIsAfterValidator"
import { DateIsBeforeValidator } from "./DateIsBeforeValidator"

Validator.extend("unique_email", new EmailUniqueValidator())
Validator.extend("unique_phone", new PhoneUniqueValidator())
Validator.extend("max_duration", new MaxDurationValidator())
Validator.extend("date_after", new DateIsAfterValidator(), { hasTarget: true })
Validator.extend("date_before", new DateIsBeforeValidator(), {
  hasTarget: true
}) 
