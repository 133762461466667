import api from "./index"

/**
 * Get user Roles
 *
 * @return {*} 
 */
export const getLanguages = () => {
  return api
    .request(`languages`)
    .withHeaders({
      Language: "en"
    })
    .get()
}

export const getSupportedLanguages = () => {
    return api.request(`supported-languages`).get()
}