export default {
  header: "Subheaders",
  headerText:
    "Компонент `v-subheader` используется для выделения разделов списков.",
  components: ["v-subheader"],
  examples: [
    {
      list: {
        header: "",
        desc:
          "Подзаголовки отлично работают со списками для описания нижеприведенной информации."
      },
      grid: {
        header: "Grid subheaders",
        desc:
          "Подзаголовок может добавить контекст к тому, что ищет пользователь."
      },
      menu: {
        header: "Menu subheaders",
        desc:
          "Использование подзаголовка может помочь разделить различные типы действий."
      },
      social: {
        header: "Подзаголовок с социальными сетями",
        desc: "Использование подзаголовка с взаимодействием в социальных сетях."
      }
    }
  ],
  props: {
    inset: "Добавляет отступы (72px)"
  }
}
