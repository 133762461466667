import { logLevel, appName } from "../config"
import moment from "moment-timezone"

export function camel(str) {
  const camel = (str || "").replace(/-([^-])/g, g => g[1].toUpperCase())

  return capitalize(camel)
} 

export function camelActual(str) {
  return (str || "").replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ""))
}

export function kebab(str) {
  return (str || "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()
}

export function capitalize(str) {
  str = str || ""

  return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`
}

export function getLongId(id) {
  // btoa() but for node
  return Buffer.from(`gid://shopify/Product/${id}`, "binary").toString(
    "base64"
  )
}

export function findProduct(store, id) {
  return store.state.store.products.find(p => p.id === id)
}

export function isOnSale(variants) {
  return variants.some(variant => {
    return parseFloat(variant.price) < parseFloat(variant.compareAtPrice)
  })
}

export function randomNumber(min, max) {
  return Math.floor(Math.random() * max) + min
}

export function randomString(length = 5) {
  let text = ""
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

// Must be called in Vue context
export function goTo(id) {
  this.$vuetify.goTo(id).then(() => {
    if (!id) {
      return (document.location.hash = "")
    }

    if (history.replaceState) {
      history.replaceState(null, null, id)
    } else {
      document.location.hash = id
    }
  })
}

/**
 * This this undefined or null ?
 * @param input
 * @return {boolean}
 */
export const isUndefinedOrNull = input => input === undefined || input === null

/**
 * Is input value is an empty string ?
 *
 * @param input
 * @return {boolean}
 */
export const isEmptyString = input =>
  typeof input === "string" && input.length === 0

/**
 * Output number with leading zero
 *
 * @param number
 * @return {string}
 */
export const toLeadingZero = number =>
  number < 10 ? ("00" + number).slice(-2) : number.toString()

/**
 * Log to console
 *
 * @param args
 */
export const log = (...args) => {
  if (logLevel === "debug") {
    window.console.log.apply(window, [`[${appName}]`, ...args])
  }
}

/**
 * Whether input value is object
 *
 * @param input
 * @returns {boolean}
 */
export const isObject = input => typeof input === "object"

/**
 * Whether input value is a file
 *
 * @param input
 * @return {boolean}
 */
export const isFile = input => input instanceof File

/**
 * Is this string?
 *
 * @param input
 * @return {boolean}
 */
export const isString = input => typeof input === "string"

/**
 * Is this function?
 *
 * @param input
 * @return {boolean}
 */
export const isFunction = input => typeof input === "function"

/**
 * Is this an array?
 *
 * @param input
 * @return {boolean}
 */
export const isArray = input => Array.isArray(input)

/**
 * Remove item from array
 *
 * @param array
 * @param item
 * @returns {Object}
 */
export const arrayRemove = (array, item) => {
  const index = array.indexOf(item)

  if (index > -1) {
    array.splice(array.indexOf(item), 1)
  }

  return item
}

/**
 * Massively remove items from an array
 *
 * @param array
 * @param items
 * @returns {*}
 */
export const arrayMassRemove = (array, items) =>
  items.forEach(item => arrayRemove(array, item))

/**
 * Remove element from an array with constraints
 *
 * @param array
 * @param constraint
 * @return {Object}
 */
export const arrayRemoveConstraint = (array, constraint) => {
  const el = array.find(constraint)

  return arrayRemove(array, el)
}

/**
 * Repeat given callback x times also
 * passing to callback current
 * iteration number
 *
 * @param times
 * @param fn
 * @returns {any[]}
 */
export const repeat = (times, fn) =>
  Array.from({ length: times }, (v, i) => i)
    .map(x => fn(x))
    .slice(-1)[0]

/**
 * Cast object properties to moment instance
 *
 * @param object
 * @param properties
 * @return {*}
 */
export const momentize = (object, properties) => {
  properties.forEach(prop => {
    const value = getObjectDot(prop, object)

    const time = isUndefinedOrNull(value)
      ? value
      : moment.isMoment(value)
        ? value
        : isObject(value)
          ? moment.tz(value.date, value.timezone).local()
          : moment.tz(value, "UTC").local()

    setObjectDot(prop, object, time)
  })

  return object
}

/**
 * Strip html tags
 *
 * @param input
 * @return {*}
 */
export const stripTags = input => input.replace(/(<([^>]+)>)/gi, "")

/**
 * @param key
 * @param salt
 * @return {string}
 */
export const uniqueKey = (key, salt) => key + "_" + salt

/**
 * Get object property
 *
 * @param path
 * @param object
 * @param defaultValue
 * @return {*}
 */
export const getObjectDot = (path, object, defaultValue = null) => {
  path.split(".").every(segment => {
    object = object[segment]
    return object
  })
  return object || defaultValue
}

/**
 * Set object dot
 *
 * @param path
 * @param object
 * @param value
 */
export const setObjectDot = (path, object, value) => {
  const segments = path.split(".")
  const lastSegment = segments.splice(-1)
  const result =
    segments.length === 0 ? object : getObjectDot(segments.join("."), object)

  if (!isUndefinedOrNull(result)) {
    result[lastSegment] = value
  }
}

/**
 * @param array
 * @param path
 * @return {*}
 */
export const arrayPluck = (array, path) =>
  array.map(x => getObjectDot(path, x))

/**
 * @param array
 * @param depth
 * @return {any[]}
 */
export const flatten = (array, depth = 1) => {
  return repeat(depth, () => {
    array = [].concat.apply([], array)

    return array
  })
}

/**
 * Compose searchable fields
 *
 * @param input
 * @return {string|null}
 */
export const composeSearchables = input => {
  let { options, aliases } =
    input.options && input.aliases ? input : { options: input, aliases: {} }

  const optionOrAlias = value => (aliases[value] ? aliases[value] : value)

  let output = ""
  for (let option in options) {
    const value = options[option]
    if (isUndefinedOrNull(value) || isEmptyString(value)) continue
    output += optionOrAlias(option) + ":" + value + ";"
  }
  return output || null
}

/**
 * Round number to closes number
 *
 * @param number
 * @param toTarget
 * @return {number}
 */
export const roundTo = (number, toTarget) =>
  Math.round(parseInt(number) / toTarget) * toTarget

/**
 * Noop function
 */
export const noop = () => {}

/**
 * Create array of range
 *
 * @param from
 * @param to
 * @return {Array}
 */
export const makeRange = (from, to) => {
  const result = []

  for (from; from <= to; from++) {
    result.push(from)
  }

  return result
}

/**
 * Make object from array
 *
 * @param array
 * @param key
 * @return {*}
 */
export const toMap = (array, key) => {
  return array.reduce((carry, item) => {
    carry[item[key]] = item

    return carry
  }, {})
}

/**
 * Safe read property from object
 *
 * @param object
 * @return {*}
 */
export const optional = object => object || {}

/**
 * Laravel's tap helper
 *
 * @param value
 * @param callback
 * @returns {*}
 */
export const tap = (value, callback) => {
  callback(value)

  return value
}
