export default {
  props: {
    activator:
      "Назначьте пользовательский активатор, если слот активатора не используется. Строкой может быть любой действительный querySelector, а Object может быть любым допустимым узлом",
    allowOverflow: "Удалить перемещение переполнения для содержимого",
    maxWidth: "Устанавливает максимальную ширину для содержимого",
    minWidth: `Устанавливает минимальную ширину для содержимого`,
    nudgeBottom: "Сдвиг содержимое вниз",
    nudgeLeft: "Сдвиг содержимого влево",
    nudgeRight: "Сдвиг содержимого вправо",
    nudgeTop: "Сдвиг содержимого вверх",
    nudgeWidth: "Сдвинуть ширину содержимого",
    offsetOverflow:
      "Заставляет компонент переворачиваться на противоположную сторону при перемещении из-за переполнения",
    positionX:
      "Используется для размещения содержимого, если не используется слот активатора",
    positionY:
      "Используется для размещения содержимого, если не используется слот активатора",
    zIndex: "Z-индекс, используемый для компонента"
  }
}
