export default {
  header: "Be a part of something bigger!",
  headerText:
    "Vuetify is made possible by an amazing community that submits issues, creates pull requests, and provides invaluable feedback. It is our job to enable you to create amazing applications. A lot of the time, you come across something that can be made better. Maybe you find a bug, or you have an idea for additional functionality. That's great! It's as easy as cloning the Vuetify repository to get started working in a development environment.",
  issuesHeader: "Reporting issues",
  issue1:
    "The issue list of this repo is exclusively for bug reports and feature requests. Non-conforming issues will be closed immediately.",
  issue2:
    "For general questions, please join the [community](https://chat.vuetifyjs.com).",
  issue3: "Try to search for your issue, it may have been answered.",
  issue4: "See if the error is reproducible with the latest version.",
  issue5:
    "If reproducible, please provide a simple [codepen](https://template.vuetifyjs.com) or repository that can be cloned to produce the expected behavior.",
  pullRequestsHeader: "Pull requests",
  pullRequest1: "All PRs should be made to the `dev` branch of Vuetify.",
  pullRequest2:
    "For changes and feature requests, please include an example of what you are trying to solve and an example of the markup",
  pullRequest3:
    "For bug fixes please reference the issue # that the PR resolves",
  localDevHeader: "Setup local dev",
  localDevText1:
    "Vuetify contains a local dev environment that you can bootup to test new features, functionality, and components. First, clone the repository:",
  localDevText2:
    "After cloned, use your cli to <kbd>cd vuetify</kbd>. Once in the directory, you will need to create a new file in the <kbd>dev</kbd> directory called `Playground.vue`. An example has been provided, `Playground.example.vue`, which you can rename. Keep in mind, this file is not tracked by version control. Once copied, go back to your cli and run:",
  localDevText3:
    "This will start a local node server located at [http://localhost:8080](http://localhost:8080). Now from your code editor you can easily load components and test whatever you want at lightning speed!"
}
