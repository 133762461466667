<template>
  <v-toolbar 
    v-if="toolbar"
    :fixed="fixedToolbar"
    :class="navToolbarScheme"
    :clipped-left="toolbarClippedLeft"
    app
    flat
  >
    <v-toolbar-side-icon
      class="hidden-lg-and-up"
      @click.stop="$store.dispatch('toggleDrawer', !navDrawer)"
    />

    <v-flex xs2>
      <v-toolbar-title class="ml-0" v-text="title" />
    </v-flex>

    <v-flex>
      <!--      <v-btn :to="{ name: 'home' }" flat>-->
      <!--        home-->
      <!--      </v-btn>-->

      <v-btn
        v-if="showByPermission()"
        :to="{ name: 'users' }"
        :class="{ 'v-btn--active': subIsActive('/moderation') }"
        flat
      >
        moderation
      </v-btn>

      <v-btn
        v-if="showByPermission()"
        :to="{ name: 'transaction' }"
        :class="{ 'v-btn--active': subIsActive('/transaction') }"
        flat
      >
        Transaction History
      </v-btn>

      <v-btn
        v-if="showByPermission()"
        :to="{ name: 'settings_page' }"
        :class="{ 'v-btn--active': subIsActive('/settings') }"
        flat
      >
        settings
      </v-btn>

      <v-btn
        v-if="showByPermission()"
        :to="{ name: 'random_avatar' }"
        flat
      >
        random avatar
      </v-btn>

      <v-btn
        v-if="showByPermission()"
        :to="{ name: 'advertising_active' }"
        :class="{ 'v-btn--active': subIsActive('/advertising') }"
        flat
      >
        Advertising
      </v-btn>
      <v-btn
        :to="{ name: 'parser_need_approve_posts', params: { name: '9gag' } }"
        :class="{ 'v-btn--active': subIsActive('/parser') }"
        flat
      >
        Parser
      </v-btn>
      <!--      //TODO parser-->
      <!--      <v-btn-->
      <!--          :to="{ name: 'parser_need_approve_posts' }"-->
      <!--          :class="{ 'v-btn&#45;&#45;active': subIsActive('/parser') }"-->
      <!--          flat-->
      <!--      >-->
      <!--        Parser-->
      <!--      </v-btn>-->
      <!--      <a :href="`${SiteUrl}analytics`" target="_blank">-->
      <!--        {{ `${SiteUrl}analytics` }}-->
      <!--      </a>-->
      <!--      <a-->
      <!--        href="http://analytic.admin.memes.redentu.com/"-->
      <!--        target="_blank"-->
      <!--        class="v-btn v-btn&#45;&#45;flat v-btn&#45;&#45;router theme&#45;&#45;dark"-->
      <!--      >-->
      <!--        analytic-->
      <!--      </a>-->
      <a
        v-if="showByPermission()"
        href="/analytics"
        target="_blank"
        class="v-btn v-btn--flat v-btn--router theme--dark"
      >
        analytic
      </a>
    </v-flex>

    <!--    <v-btn-->
    <!--      icon-->
    <!--      light-->
    <!--      class="hidden-md-and-down"-->
    <!--      @click.stop="toggleMiniVariantMode"-->
    <!--    >-->
    <!--      <v-tooltip bottom v-if="navMiniVarient" color="sidebar">-->
    <!--        <v-icon slot="activator">fas fa-arrow-circle-right</v-icon>-->
    <!--        <span>Expand</span>-->
    <!--      </v-tooltip>-->
    <!--      <v-tooltip bottom v-else color="sidebar">-->
    <!--        <v-icon slot="activator">fas fa-arrow-circle-left</v-icon>-->
    <!--        <span>Collapse</span>-->
    <!--      </v-tooltip>-->
    <!--    </v-btn>-->
    <!--  -->

    <v-spacer />
    <v-menu v-if="getUserInfo" offset-y>
      <v-avatar v-if="getUserInfo.image" slot="activator" size="40">
        <img :src="getUserInfo.image" :alt="getUserInfo.image">
      </v-avatar>
      <v-avatar v-if="getUserInfo.image === null" slot="activator" size="40">
        <div
          class="avatar-letter"
          v-text="getUserInfo && getUserInfo.username.substring(0, 1)"
        ></div>
      </v-avatar>
      <v-list dense>
        <v-list-tile avatar>
          <v-list-tile-avatar v-if="getUserInfo.image">
            <img :src="getUserInfo.image" :alt="getUserInfo.image">
          </v-list-tile-avatar>
          <v-list-tile-avatar v-if="getUserInfo.image === null">
            <div
              class="avatar-letter"
              v-text="getUserInfo && getUserInfo.username.substring(0, 1)"
            ></div>
          </v-list-tile-avatar>
          <v-list-tile-content v-if="getUserInfo">
            <v-list-tile-title
              v-text="getUserInfo.username"
            />
            <v-list-tile-sub-title>
              {{
                getUserInfo.role
              }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <!--        <v-divider></v-divider>-->
        <!--        <v-list-tile @click="() => {}">-->
        <!--          <v-list-tile-avatar>-->
        <!--            <v-icon>person</v-icon>-->
        <!--          </v-list-tile-avatar>-->
        <!--          <v-list-tile-title>Edit Profile</v-list-tile-title>-->
        <!--        </v-list-tile>-->
        <!--        <v-list-tile @click="() => {}">-->
        <!--          <v-list-tile-avatar>-->
        <!--            <v-icon>settings_applications</v-icon>-->
        <!--          </v-list-tile-avatar>-->
        <!--          <v-list-tile-title>Settings</v-list-tile-title>-->
        <!--        </v-list-tile>-->
        <!--        <v-list-tile @click="() => {}">-->
        <!--          <v-list-tile-avatar>-->
        <!--            <v-icon>mail</v-icon>-->
        <!--          </v-list-tile-avatar>-->
        <!--          <v-list-tile-title>Inbox</v-list-tile-title>-->
        <!--        </v-list-tile>-->
        <v-divider />
        <v-list-tile @click="onLogoutClickListener">
          <v-list-tile-avatar>
            <v-icon>power_settings_new</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title>Sign Out</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>
<script>
import { logoutRequest } from "../../api/auth"
import { authUser } from "@/data/dummyData"
import { mapGetters } from "vuex"
import { resetAccessToken } from "../../utils/axios"
import { SiteUrl } from "../../config"
export default {
  data() {
    return {
      title: "Admincab",
      SiteUrl: ""
    }
  },
  computed: {
    ...mapGetters({
      navDrawer: "navDrawer",
      toolbarClippedLeft: "toolbarClippedLeft",
      fixedToolbar: "fixedToolbar",
      toolbar: "toolbarVisibility",
      navToolbarScheme: "navToolbarScheme",
      navMiniVarient: "navMiniVarient",
      getUserInfo: "getUserInfoAndPermissions"
    }),
    authUser() {
      return authUser
    },
    // showByPermission(){
    //   console.log(this.getUserInfo.role)
    //   console.log(this.user.role)
    //   return this.user.role === 'Admin' || this.user.role === 'Moderator'
    //   // return true
    // }
  },
  mounted() {
    this.SiteUrl = SiteUrl
  },
  methods: {
    showByPermission () {
      return this.user.role === 'Admin' || this.user.role === 'Moderator'
      // return this.getUserInfo.role === 'Admin' || this.getUserInfo.role === 'Moderator'
    },
    toggleMiniVariantMode() {
      this.$store.dispatch("toggleMiniVariantMode")
      this.$store.dispatch("toggleMiniVarient")
    },
    onLogoutClickListener() {
      logoutRequest()
        .then(() => {
          this.$notificationShow("You have been logged out")
          this.removeToken()
          this.$router.push({ name: "login" })
        })
        .catch(this.$notificationHandleResponseError)
    },
    removeToken() {
      this.$ls.set("user", "")
      this.$ls.set("access_token", "")
      resetAccessToken()
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0
      })
    }
  }
}
</script>

<style scoped>
.input-wrap {
  margin-right: 30px;
}
.avatar-letter {
  background-color: #1b003d;
  text-transform: uppercase;
  border-radius: 50%;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  position: relative;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
