export default {
  header: "Stepper",
  headerText:
    "Компонент `v-stepper` отображает прогресс по пронумерованным шагам.",
  components: [
    "v-stepper",
    "v-stepper-step",
    "v-stepper-content",
    "v-stepper-header"
  ],
  examples: [
    {
      example: {
        header: "Пример",
        desc:
          "stepper может использоваться для множества сценариев, включая карты для покупок, создание записей и многое другое."
      },
      editable: {
        header: "Редактируемые шаги",
        desc:
          "Редактируемый шаг может быть выбран пользователем в любой точке и будет перемещать их на этот шаг."
      },
      nonEditable: {
        header: "Не редактируемые шаги",
        desc:
          "Не редактируемые шаги заставляют пользователя пройти линейно через ваш процесс."
      },
      optional: {
        header: "Дополнительные шаги",
        desc: "Необязательный шаг можно вызвать с помощью sub-text."
      },
      horizontal: {
        header: "Горизонтальные шаги",
        desc:
          "Горизонтальные steppers перемещают пользователей по оси X с помощью определенных шагов."
      },
      vertical: {
        header: "Вертикальные шаги",
        desc:
          "Вертикальные steppers перемещают пользователей по оси Y и в противном случае работают точно так же, как и их горизонтальная копия."
      },
      linear: {
        header: "Линейные steppers",
        desc:
          "Линейные steppers всегда перемещают пользователя по определенному пути."
      },
      nonLinear: {
        header: "Нелинейные steppers",
        desc:
          "Нелинейные steppers позволяют пользователю перемещаться по вашему процессу любым способом."
      },
      alternateLabels: {
        header: "Альтернативные labels",
        desc:
          "У Steppers также есть альтернативный стиль ярлыка, который помещает заголовок под сам шаг."
      },
      error: {
        header: "Многострочное состояние ошибки",
        desc:
          "Может отображаться состояние ошибки, чтобы уведомить пользователя о некоторых действиях, которые необходимо предпринять."
      },
      alternateError: {
        header: "Альтернативное определение состояния многострочной метки",
        desc:
          "Состояние ошибки также может быть применено к альтернативному стилю этикетки."
      },
      verticalError: {
        header: "Вертикальное многострочное состояние ошибки",
        desc: "То же самое относится и к вертикальным steppers."
      },
      dynamic: {
        header: "Динамические шаги",
        desc:
          "Steppers могут динамически добавлять или удалять свои шаги. Если текущий активный шаг удален, обязательно учтите это, изменив применяемую модель."
      }
    }
  ],
  props: {
    altLabels: "Помещает метки под шаг",
    complete: "Пометка шаг завершен",
    completeIcon: "Значок для отображения, когда шаг отмечен как завершенный",
    editable: "Помечает шаг как редактируемый",
    editIcon: "Значок, отображаемый при редактировании шага",
    errorIcon: "Значок для отображения, если на шаге имеется ошибка",
    nonLinear: "Разрешить пользователю перейти на любой шаг",
    vertical: "Показывать шаги по вертикали",
    "v-stepper-step": {
      step: "Содержимое для отображения внутри шага"
    },
    "v-stepper-content": {
      step: "Устанавливает шаг для связывания содержимого с"
    }
  }
}
