import Vue from "vue"
import "./utils/validators/index"
import "./plugins/vuetify"
import "./plugins/vuse"
import "./plugins/core"
import "./mixins/index"
import App from "./App.vue"
import router from "./router"
import { store } from "./store" 
import { createI18n } from "./i18n/index"
import "./assets/styles/index.scss"
import { initializeResponseInterceptors } from "./api/utils"
import { initializeRouterAuthMiddleware } from "./router/middleware"
import Echo from 'laravel-echo'

Vue.config.productionTip = false

const i18n = createI18n()

// let count = 0;
// Vue.mixin({
//   created() {
//     console.log(++count);
//   },
//   destroyed() {
//     console.log(--count);
//   }
// });

window.io = require('socket.io-client')
window.Echo = new Echo({
  broadcaster: 'socket.io',
  host: process.env.VUE_APP_SOCKET_HOST,
  autoConnect: false
})

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    initializeRouterAuthMiddleware(this)
  },
  created() {
    initializeResponseInterceptors(this)
  },
  render: h => h(App)
}).$mount("#app")
