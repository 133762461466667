import Formui from "./Formui"
import Vuetrend from "./Vuetrend"
import Chartjs from "./Chartjs"
import Vuebar from "./vuebar"
import Cardwidgets from "./Cardwidgets"
import Chartwidgets from "./Chartwidgets"

export default {
  Formui,
  Vuetrend,
  Chartjs,
  Vuebar,
  Cardwidgets,
  Chartwidgets
}
