export default {
  props: {
    appendIcon:
      "Добавьте значок к компоненту, используя тот же синтаксис, что и `v-icon`",
    appendIconCb: "Обратный вызов для значка при нажатии",
    disabled: "Ввод отключен",
    hideDetails: "Скрывает подсказку, ошибки проверки",
    hint: "Текст подсказки",
    label: "Устанавливает метку ввода",
    persistentHint: "Форсирование подсказки всегда быть видимым",
    placeholder: `Устанавливает подсказку ввода текста`,
    prependIcon:
      "Подключите иконку к компоненту, используя тот же синтаксис, что и `v-icon`",
    prependIconCb:
      "Функция обратного вызова для подключения события клика на иконку",
    readonly: "Выставляет состояние только чтения",
    required:
      "Обозначает ввод обязательным. Добавляет звездочку к концу метки. Не выполняет никаких проверок.",
    tabindex: "Tabindex ввода",
    toggleKeys:
      "Массив кодов клавиш, которые будут переключаться на ввод (если он поддерживает переключение)",
    value: "Значение ввода"
  },
  events: {
    blur: "Событие когда input blurred",
    change: "Событие когда select изменен путем взаимодействия с пользователем",
    "update:error": "`update.sync` событие"
  }
}
