import api from "./index"

/**
 * post approve
 *
 * @param id
 * @return {*}
 */
export const removalDetails = id => {
  return api.request(`admin/post/removal/details?post_id=${id}`).get()
}
 