export default {
  props: {
    activeClass:
      'Класс связан, когда компонент активен. **warning** В зависимости от компонента это может вызвать побочные эффекты. Если вам нужно добавить собственный класс поверх значения по умолчанию, просто выполните `active-class = "default-class your-class"`',
    append: "Vue Router router-link",
    disabled: "Элемент маршрута отключен",
    exact:
      "Точно сопоставьте ссылку. Без этого «/» будет соответствовать каждому маршруту",
    exactActiveClass: "Vue Router router-link",
    href: "Обозначает тег компонента `<a>`",
    nuxt: "Указывает, что ссылка является nuxt-link",
    replace: "Vue Router router-link",
    to: "Обозначает тег компонента `<router-link>`",
    tag: "Укажите настраиваемый тег для использования в компоненте",
    target: "Укажите целевой атрибут, работает только с тегом привязки."
  }
}
