export default {
  header: "무료 또는 프리미엄 테마들",
  headerText:
    "Vuetify 는 빠르게 시작할 수 있도록 디자인된 **무료** 테마와 **유료(프리미엄)** 테마를 제공합니다. 무료 테마들은 vue-cli 를 사용하거나 간단히 소스를 다운로드해서 사용할 수 있습니다.",
  buy: "정보와 데모",
  free: "무료",
  demo: "데모",
  comingSoon: "개봉박두",
  sourceCode: "소스코드",
  templates: [
    {
      screenshot: "/static/doc-images/starter/vuetify-premium.jpg",
      title: "알파 테마 (Alpha Theme)",
      tag: "프리미엄",
      description:
        "Complete theme experience including enhanced vue-cli, full documentation, 5 custom components and much more!",
      price: "25$",
      demoUrl: ""
    },
    {
      screenshot: "/static/doc-images/starter/vuetify-parallax-starter.png",
      title: "Parallax",
      description:
        "This beautiful single page parallax is a great home page for any application.",
      sourceUrl: "vuetifyjs/parallax-starter",
      demoUrl: "/themes/parallax-starter"
    },
    {
      screenshot: "/static/doc-images/starter/vuetify-blog-starter.png",
      title: "블로그",
      description:
        "A simple template that features a clean interface for creating a blog or blog-like application.",
      sourceUrl: "vuetifyjs/blog-starter",
      demoUrl: "/themes/blog-starter"
    }
  ]
}
