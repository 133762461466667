export default {
  "": {
    h1: "",
    title: "Vue.js Material Component Framework",
    description:
      "Vuetify.js is a reusable semantic component framework for Vue.js. It aims to provide clean, semantic and reusable components.",
    keywords:
      "vue, vue 2, vue js, vue.js, material design, vue components, material design components, vuetify, vuetify.js"
  },
  "*": {
    h1: "",
    title: "404",
    description: "",
    keywords: ""
  },
  "getting-started/quick-start": {
    h1: "Quick Start",
    title: "Quick Start",
    description: "Get started with Vue and Vuetify in no time.",
    keywords: "quick start, vuetify templates, get started"
  },
  "getting-started/starter-templates": {
    h1: "Starter Templates",
    title: "Starter Templates",
    description:
      "Understand the layout and structure of your application. Check out examples.",
    keywords: "templates, vuetify"
  },
  "getting-started/why-vuetify": {
    h1: "Why use Vuetify?",
    title: "Why Vuetify?",
    description: "Why you should choose Vuetify over other Vue frameworks.",
    keywords: "why vuetify, why vue, why choose vuetify"
  },
  "getting-started/frequently-asked-questions": {
    h1: "Frequently asked questions",
    title: "Frequently asked questions",
    description: "Stuck on a problem? Check these frequently asked questions.",
    keywords: "frequently asked questions, faq"
  },
  "getting-started/sponsors-and-backers": {
    h1: "Sponsors and backers",
    title: "Sponsors and backers",
    description:
      "Help support Vuetify.js by backing the development of new features",
    keywords: "sponsor, backer, donations"
  },
  "getting-started/contributing": {
    h1: "Contributing",
    title: "Contributing",
    description: "Help develop Vuetify with new features and functionality.",
    keywords: "contribute, contributing, feature request"
  },
  "getting-started/roadmap": {
    h1: "Roadmap",
    title: "Roadmap",
    description:
      "The upcoming planned features and new functionality coming to Vuetify. New vue components, new directives, and much much more!.",
    keywords: "roadmap, future plans"
  },
  "motion/transitions": {
    h1: "Transitions",
    title: "Transitions",
    description: "Make an amazing application with smooth built in animations.",
    keywords: "motion, transitions"
  },
  "motion/scrolling": {
    h1: "Scrolling",
    title: "Scrolling",
    description: "Scroll to a target location on the page.",
    keywords: "scrolling, smooth scroll, transitions"
  },
  "style/colors": {
    h1: "Colors",
    title: "Material color palette",
    description: "Easily apply any color from the material design palette.",
    keywords: "colors"
  },
  "style/theme": {
    h1: "Theme",
    title: "Application theming",
    description:
      "Setup your custom application theme by changing a few variables.",
    keywords: "themes"
  },
  "style/typography": {
    h1: "Typography",
    title: "Application typography",
    description:
      "View the various typography styles. From headings to captions, with various weights and sizes.",
    keywords: "typography, headings, titles"
  },
  "style/content": {
    h1: "Content",
    title: "Content styles",
    description: "Vuetify has custom styling for default html markup.",
    keywords: "content, html, markup"
  },
  "layout/pre-defined": {
    h1: "Pre-defined",
    title: "Application layouts",
    description: "Select from a multitude of official material design layouts.",
    keywords:
      "pre-defined layouts, layouts, application layout, material design layouts"
  },
  "layout/pre-made-themes": {
    h1: "Pre-made themes",
    title: "Free / Premium Themes",
    description:
      "Vuetify offers numerous pre-build starter and premium themes. Kickstart your next application today, no design skills needed.",
    keywords: "vuetify themes, pre-built material themes, premium themes"
  },
  "layout/grid": {
    h1: "Grid",
    title: "Layout grid system",
    description:
      "Vuetify supports the material design 12 point grid for laying out your application.",
    keywords: "grid, layout, flex"
  },
  "layout/grid-lists": {
    h1: "Grid lists",
    title: "Grid List Component",
    description: "Grid list component for Vuetify Framework",
    keywords: "grid lists, grid"
  },
  "layout/spacing": {
    h1: "Spacing",
    title: "Spacing helpers",
    description:
      "Spacing helper classes allow you to apply margin or padding to any element.",
    keywords: "spacing helper classes"
  },
  "layout/alignment": {
    h1: "Alignment",
    title: "Alignment helpers",
    description:
      "Alignment helpers assist in modifying your application layout on varying viewport sizes.",
    keywords: "alignment helper classes"
  },
  "layout/display": {
    h1: "Display",
    title: "Display helpers",
    description:
      "Display helper classes allow you to control when elements should display based upon viewport.",
    keywords: "display helper classes"
  },
  "layout/elevation": {
    h1: "Elevation",
    title: "Elevation helpers",
    description:
      "Elevation helper classes allow you to control relative depth, or distance, between two surfaces along the z-axis.",
    keywords: "elevation helper classes"
  },
  "layout/sandbox": {
    h1: "Sandbox",
    title: "Sandbox",
    description:
      "Play around with the available configuring options for your next application.",
    keywords: "sandbox, vuetify, layout sandbox"
  },
  "components/api-explorer": {
    h1: "API Explorer",
    title: "API Explorer",
    description: "Search the API of all Vuetify components.",
    keywords: "material design components, components api, vuetify api"
  },
  "components/alerts": {
    h1: "Alerts",
    title: "Alert Component",
    description:
      "The v-alert component is used to convey information to the user. Designed to stand out, the alerts come in four contextual styles, success, info, warning, and error.",
    keywords: "alerts, components"
  },
  "components/avatars": {
    h1: "Avatars",
    title: "Avatar Component",
    description:
      "The v-avatar component is used to control the size and border radius of an image.",
    keywords: "avatars, components"
  },
  "components/badges": {
    h1: "Badges",
    title: "Badges",
    description: "Badge component for the Vuetify framework.",
    keywords: "badges"
  },
  "components/breadcrumbs": {
    h1: "Breadcrumbs",
    title: "Breadcrumbs Component",
    description:
      "The v-breadcrumbs component is a navigational helper for pages. It can accept a Material Icons icon or characters as a divider.",
    keywords: "breadcrumbs, crumbs"
  },
  "components/bottom-navigation": {
    h1: "Bottom navigation",
    title: "Bottom Navigation Component",
    description:
      "The v-bottom-nav component is primarily for mobile use and acts as the primary navigation.",
    keywords: "bottom nav, material nav, nav, bottom navigation, navigation"
  },
  "components/bottom-sheets": {
    h1: "Bottom sheets",
    title: "Bottom sheets",
    description:
      "The v-bottom-sheets component is used for elevating content above other elements in a dialog style fashion",
    keywords: "bottom sheets, bottom dialog, bottom modal"
  },
  "components/cards": {
    h1: "Cards",
    title: "Cards",
    description: "Card component for Vuetify framework.",
    keywords: "vuetify, cards, components"
  },
  "components/buttons": {
    h1: "Buttons",
    title: "Button Component",
    description: "Button component for Vuetify Framework.",
    keywords: "vuetify, buttons, components"
  },
  "components/floating-action-buttons": {
    h1: "Floating Action Buttons",
    title: "Floating Action Button Component",
    description: "Floating Action Button component for Vuetify Framework.",
    keywords: "vuetify, buttons, fab, floating button, floating action button"
  },
  "components/carousels": {
    h1: "Carousels",
    title: "Carousels Component",
    description: "Carousel component for Vuetify Framework.",
    keywords: "carousel"
  },
  "components/chips": {
    h1: "Chips",
    title: "Chip Component",
    description: "Chip component for Vuetify Framework.",
    keywords: "chip"
  },
  "components/dividers": {
    h1: "Dividers",
    title: "Dividers Component",
    description: "Divider component for Vuetify Framework.",
    keywords: "carousel"
  },
  "components/data-iterator": {
    h1: "Data iterator",
    title: "Data iterator Component",
    description:
      "The v-data-iterator component is used for displaying data, and shares a majority of its functionality with the v-data-table component. Features include sorting, searching, pagination, and selection.",
    keywords: "data iterator, tables"
  },
  "components/data-tables": {
    h1: "Data tables",
    title: "Data tables Component",
    description:
      "The v-data-table component is used for displaying tabular data. Features include sorting, searching, pagination, inline-editing, header tooltips, and row selection.",
    keywords: "data tables, tables"
  },
  "components/dialogs": {
    h1: "Dialogs",
    title: "Dialogs Component",
    description:
      "A dialog informs a user about a specific task and may contain critical information.",
    keywords: "dialogs"
  },
  "components/expansion-panels": {
    h1: "Expansion panel",
    title: "Expansion panel Component",
    description: "Expansion panel component for Vuetify framework.",
    keywords: "expansion panel"
  },
  "components/footer": {
    h1: "Footer",
    title: "Footer Component",
    description: "Footer component for Vuetify Framework",
    keywords: "footer"
  },
  "components/forms": {
    h1: "Forms",
    title: "Form Component",
    description: "Form component for Vuetify Framework",
    keywords: "form"
  },
  "components/icons": {
    h1: "Icons",
    title: "Icon Component",
    description: "Icon component for Vuetify Framework",
    keywords: "icon"
  },
  "components/lists": {
    h1: "Lists",
    title: "List Component",
    description: "List component for Vuetify Framework",
    keywords: "list, lists"
  },
  "components/jumbotrons": {
    h1: "Jumbotrons",
    title: "Jumbotron Component",
    description: "Jumbotron component for Vuetify Framework",
    keywords: "jumbotrons"
  },
  "components/menus": {
    h1: "Menus",
    title: "Menu Component",
    description: "Menu component for Vuetify Framework",
    keywords: "menu"
  },
  "components/navigation-drawers": {
    h1: "Navigation drawers",
    title: "Navigation drawer",
    description: "Navigation drawer component for Vuetify Framework",
    keywords: "navigation, drawer, sidebar, sidenav"
  },
  "components/paginations": {
    h1: "Pagination",
    title: "Pagination Component",
    description:
      "The v-pagination component is used to separate long sets of data so that it is easier for a user to consume information.",
    keywords: "pagination, components"
  },
  "components/parallax": {
    h1: "Parallax",
    title: "Parallax Component",
    description:
      "The v-parallax component creates a 3d effect that makes an image appear to scroll slower than the window.",
    keywords: "parallax, components"
  },
  "components/date-pickers": {
    h1: "Date Pickers",
    title: "Date Picker Component",
    description: "Date Picker component for the Vuetify framework.",
    keywords: "vuetify, picker, date picker, component"
  },
  "components/time-pickers": {
    h1: "Time pickers",
    title: "Time Picker Component",
    description: "Time Picker component for the Vuetify framework.",
    keywords: "vuetify, picker, time picker, component"
  },
  "components/progress": {
    h1: "Progress",
    title: "Progress",
    description: "Progress components for the Vuetify framework.",
    keywords: "progress"
  },
  "components/sliders": {
    h1: "Sliders",
    title: "Slider Component",
    description:
      "The v-slider component is a better visualization of the number input. It is used for gathering numerical user data.",
    keywords: "slider, components"
  },
  "components/snackbars": {
    h1: "Snackbars & toasts",
    title: "Snackbar & toasts Component",
    description: "Snackbar component for Vuetify Framework",
    keywords: "snackbar"
  },
  "components/steppers": {
    h1: "Steppers",
    title: "Stepper Component",
    description: "Stepper component for Vuetify Framework",
    keywords: "stepper"
  },
  "components/selection-controls": {
    h1: "Selection Controls",
    title: "Selection controls Input Components",
    description: "Selection controls input components for Vuetify Framework",
    keywords: "vuetify, form, components"
  },
  "components/selects": {
    h1: "Selects",
    title: "Selects Input Components",
    description: "Selects input components for Vuetify Framework",
    keywords: "vuetify, form, components"
  },
  "components/subheaders": {
    h1: "Subheaders",
    title: "Subheaders",
    description: "Subheaders are used for separating lists of content.",
    keywords: "subheader"
  },
  "components/tabs": {
    h1: "Tabs",
    title: "Tabs",
    description: "Tabs component for the Vuetify framework.",
    keywords: "tabs"
  },
  "components/text-fields": {
    h1: "Text fields",
    title: "Text fields",
    description: "Text field component for the Vuetify framework.",
    keywords: "text field"
  },
  "components/toolbars": {
    h1: "Toolbars",
    title: "Toolbars",
    description: "Toolbars component for the Vuetify framework.",
    keywords: "toolbars"
  },
  "components/tooltips": {
    h1: "Tooltip",
    title: "Tooltip",
    description: "Tooltip components for the Vuetify framework.",
    keywords: "tooltips"
  },
  "directives/resizing": {
    h1: "Resizing",
    title: "Resizing",
    description: "Resize directive for the Vuetify framework.",
    keywords: "resize, resize directive, window resize directive"
  },
  "directives/ripples": {
    h1: "Ripple",
    title: "Ripple",
    description: "Ripple directive for the Vuetify framework.",
    keywords: "ripples, ink"
  },
  "directives/scrolling": {
    h1: "Scrolling",
    title: "Scrolling",
    description: "Scroll directive for the Vuetify framework.",
    keywords: "scroll, scroll directive, window scroll directive"
  },
  "directives/touch-support": {
    h1: "Touch Support",
    title: "Touch Support",
    description: "Touch directive for the Vuetify framework.",
    keywords: "touch, touch directive, mobile touch directive"
  },
  "examples/layouts/baseline": {
    h1: "Baseline",
    title: "Baseline layout example",
    description: "Baseline layout for the Vuetify.js framework",
    keywords: "template, pre-made, vuetify layout"
  },
  "examples/layouts/baselineFlipped": {
    h1: "Baseline Flipped",
    title: "Baseline Flipped layout example",
    description: "Baseline Flipped layout for the Vuetify.js framework",
    keywords: "template, pre-made, vuetify layout"
  },
  "examples/layouts/centered": {
    h1: "Centered",
    title: "Centered layout example",
    description: "Centered layout for the Vuetify.js framework",
    keywords: "template, pre-made, vuetify layout"
  },
  "examples/layouts/complex": {
    h1: "Complex",
    title: "Complex layout example",
    description: "Complex layout for the Vuetify.js framework",
    keywords: "template, pre-made, vuetify layout"
  },
  "examples/layouts/dark": {
    h1: "Dark",
    title: "Dark layout example",
    description: "Dark layout for the Vuetify.js framework",
    keywords: "template, pre-made, vuetify layout"
  },
  "examples/layouts/googleContacts": {
    h1: "Google Contacts",
    title: "Google Contacts layout example",
    description: "Google Contacts layout for the Vuetify.js framework",
    keywords: "template, pre-made, vuetify layout"
  },
  "examples/layouts/googleKeep": {
    h1: "Google Keep",
    title: "Google Keep layout example",
    description: "Google Keep layout for the Vuetify.js framework",
    keywords: "template, pre-made, vuetify layout"
  },
  "examples/layouts/googleYoutube": {
    h1: "Google Youtube",
    title: "Google Youtube layout example",
    description: "Google Youtube layout for the Vuetify.js framework",
    keywords: "template, pre-made, vuetify layout"
  },
  "examples/layouts/sandbox": {
    h1: "Layout Sandbox",
    title: "Layout Sandbox",
    description: "Layout Sandbox for the Vuetify.js framework",
    keywords: "template, pre-made, vuetify layout, sandbox"
  },
  "guides/a-la-carte": {
    h1: "A La Carte",
    title: "A La Carte",
    description:
      "Use only the components you need. Single component importing allows you to select what you want and leave the rest.",
    keywords:
      "a la carte, a-la-carte, vuetify single import, vuetify import, component importing"
  },
  "guides/server-side-rendering": {
    h1: "Server side rendering",
    title: "Server side rendering",
    description: "Server side rendering guide for the Vuetify framework.",
    keywords: "server side rendering, ssr"
  },
  "theme-generator": {
    h1: "Theme generator",
    title: "Material design theme generator",
    description:
      "Create a custom Material Design theme for your next application.",
    keywords:
      "material theme, theme generator, material theme generator, vuetify theme generator"
  },
  store: {
    h1: "Vuetify Store",
    title: "Store",
    description:
      "Vuetify Themes, fitted soft t-shirts, vinyl stickers and more.",
    keywords: "vuetify store, vuetify shop, vuetify merch, vuetify themes"
  },
  "store/cart": {
    h1: "",
    title: "Your Cart",
    description: "Vuetify cart checkout",
    keywords: "vuetify store, vuetify shop, vuetify merch, vuetify themes"
  },
  "store/thank-you": {
    h1: "",
    title: "Thank you for supporting Vuetify",
    description: "Thank you for supporting open source and Vuetify",
    keywords: "thank you from vuetify"
  },
  "store/licensing": {
    h1: "",
    title: "Product licensing",
    description: "",
    keywords: ""
  }
}
