export default {
  header: "Snackbar",
  headerText:
    "Компонент `v-snackbar` используется для отображения быстрого сообщения пользователю. Snackbars поддерживают позиционирование, задержку и обратные вызовы.",
  components: ["v-snackbar"],
  examples: [
    {
      position: {
        header: "Позиционирование",
        desc:
          "Стандартный _snackbar_ полезен для привлечения внимания к некоторой функции, которая только что произошла."
      },
      contextual: {
        header: "Контекстуальный",
        desc:
          "Вы также можете применить цвет к _snackbar_ , чтобы лучше соответствовать вашей реализации."
      }
    }
  ],
  props: {
    multiLine: "Делает _snackbar_ сверху(мобильную)",
    timeout: "Время ожидания, пока _snackbar_ не будет автоматически скрыт",
    vertical: "Стек _snackbar_ содержимое вертикально(мобильном)"
  }
}
