export default {
  header: "Разметка",
  headerText: `Система разметки является основой каждого приложения. Ниже приведены официально поддерживаемые примеры: от настольных до мобильных приложений.`,
  markupHeader: "Разметка приложения по умолчанию",
  markupText:
    "Это пример разметки приложения по умолчанию для Vuetify. Вы можете размещать элементы макета в любом месте, пока вы применяете свойство **app**. Ключевым компонентом во всем этом является элемент `v-content`. Он будет динамически изменяться в зависимости от структуры ваших установленных компонентов **app**. Это позволяет создавать абсолютно индивидуальные решения.",
  appHeader: "Все о _app_",
  appText:
    "В Vuetify компонент `v-app` и **app** помогают загружать ваше приложение с соответствующим размером в `v-content`. Он позволяет создавать действительно уникальные интерфейсы без хлопот управления макетом. Компонент `v-app` **НЕОБХОДИМ** для всех приложений. Это точка монтирования для многих компонентов и функциональности Vuetify.",
  alert1:
    "Чтобы ваше приложение работало правильно, вы **должны** обернуть его в компонент `v-app`. Этот компонент необходим для определения контрольных точек сетки для макета. Он может существовать везде внутри body, но должен быть родителем компонентов **ВСЕХ** Vuetify.",
  alert2:
    "Применяя **app** prop автоматически применяет положение: **fixed** к элементу макета. Если ваше приложение требует элемент _absolute_, вы можете перезаписать эту функцию, используя **absolute** prop.",
  toc: [
    {
      text: "Разметка",
      href: "introduction"
    },
    {
      text: "Разметка приложения по умолчанию",
      href: "default-markup"
    },
    {
      text: "Все о приложении",
      href: "all-about-app"
    }
  ]
}
