export default {
  header: "обзор",
  headerText:
    "Будучи составной частью, Vuetify будет всегда расти. В зависимости от вашего проекта может потребоваться небольшой **размер пакета**. Система A la carte позволяет вам выбирать, какие компоненты необходимо импортировать, сильно _уменьшить_ размер сборки. **Если у вас уже установлен шаблон `a-la-carte`, вы можете перейти к [руководству](/guides/a-la-carte#application)**.",
  headerText2:
    "Для предварительно созданного проекта, демонстрирующего импорт отдельных компонентов, установите шаблон **a-la-carte** из cli, используя команду `vue init vuetifyjs/a-la-carte`. Некоторые из наших других шаблонов также включают опции для включения компонентов a-la-carte. Дополнительная информация содержится в [Кратком руководстве](/getting-started/quick-start)",
  importHeader: "Импорт компонентов",
  importText1:
    "Пакет `transform-import` не нужен для использования компонентов a-la-carte, но упрощает процесс их импорта и поэтому **рекомендуется**. Это позволяет использовать более сжатый синтаксис при импорте компонентов.",
  alert2:
    "Имейте в виду, что компоненты `Vuetify` и `VApp` должны использовать Vuetify",
  alert3:
    "Объект options, который вы передаете в качестве второго аргумента в `Vue.use`, также может включать свойства _directives_ и _transitions_.",
  importText2:
    "Если вы не используете пакет transform-import, вам нужно будет импортировать каждый компонент следующим образом:",
  importText3:
    "Вы также можете импортировать компоненты в .vue-файлы, как показано ниже.",
  styleHeader: "Требуемые стили",
  styleText1:
    "Чтобы получить все необходимые стили, нам нужно импортировать их в stylus. Для того, чтобы webpack справился с этим, вам нужно будет установить `stylus` и `stylus-loader` из **npm**.",
  alert4:
    "Для более подробного объяснения того, как настроить приложение для обработки stylus, перейдите на страницу [темы](/style/themes).",
  styleText2:
    "Теперь вам нужно будет подключить stylus в точки входа вашего приложения. Это то же место, где вы импортируете Vue и Vuetify (обычно `main.js` или `app.js`). Имейте в виду, что это требование в главном `App.vue` может вызвать медленное время загрузки, поскольку оно повторно обрабатывается при создании обновлений.",
  applicationHeader: "Приложения",
  applicationText1:
    "Перейдите в папку **src** и откройте `main.js`. Мы видим, что в шаблоне появляются некоторые компоненты, которые уже настроены для загрузки.",
  applicationText2:
    "Например, приведенный ниже код сообщает приложению, что вы используете `<v-app>`, `<v-navigation-drawer>`, `<v-footer>` и `<v-toolbar>`. После определения разметка может использоваться в любом файле .vue.",
  componentNameListHeader:
    "Список имен компонентов пользовательского интерфейса",
  componentNameListText1:
    "Некоторые компоненты, такие как `VLayout` или` VFlex`, по организационным причинам включены в другие компоненты, в данном случае `VGrid`. Чтобы узнать, какой компонент для импорта вы можете просмотреть в следующей таблице.",
  toc: [
    {
      text: "обзор",
      href: "introduction"
    },
    {
      text: "Импорт компонентов",
      href: "importing-components"
    },
    {
      text: "Требуемые стили",
      href: "required-styles"
    },
    {
      text: "Приложения",
      href: "application"
    },
    {
      text: "Список имен компонентов пользовательского интерфейса",
      href: "component-name-list"
    }
  ]
}
