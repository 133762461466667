import AppToolbar from "@/layouts/App/Toolbar"

// Pages
import LoginPage from "@/views/Pages/Authentication/LoginPage"
import Error404 from "@/views/Pages/Errors/Error404"
import Error500 from "@/views/Pages/Errors/Error500"

export const routes = [
  { path: "/", redirect: { name: "users" } },
  {
    path: "/home",
    name: "home",
    components: {
      header: AppToolbar,
      default: resolve => require(["@/views/Pages/Home/Home"], resolve)
    }
  },
  {
    path: "/moderation",
    name: "moderation",
    components: {
      header: AppToolbar,
      default: resolve =>
        require(["@/views/Pages/Moderation/Moderation"], resolve),
      meta: {
        roles: ['Admin', 'Moderator']
      }
    },
    children: [
      {
        path: "user_list/users",
        name: "users",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/UserList/layouts/AllUsersList"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "user_list/reported_users",
        name: "reported_users",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/UserList/layouts/ReportedUsersList"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "user_list/model_users",
        name: "model_users",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/UserList/layouts/ModelUsersList"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "user_list/blocked_users",
        name: "blocked_users",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/UserList/layouts/BlockedUsersList"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "user_list/user/:id",
        name: "user",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/UserList/layouts/ViewUserPage"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "user_list/reported_user/:id",
        name: "reported_user",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/UserList/layouts/ViewReportedUserPage"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "post_list/posts",
        name: "posts",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/PostList/layouts/AllPostsList"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "post_list/archive_posts",
        name: "archive_posts",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/PostList/layouts/ArchivePostsList"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "post_list/need_approve_posts",
        name: "need_approve_posts",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/PostList/layouts/NeedApprovePostsList"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "post_list/removal_posts",
        name: "removal_posts",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/PostList/layouts/RemovalRequestPostsList"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "post_list/reported_posts",
        name: "reported_posts",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/PostList/layouts/ReportedPostsList"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "post_list/removal_request_post/:id",
        name: "removal_request_post",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/PostList/layouts/RemovalRequestPost"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "post_list/reported_post/:id",
        name: "reported_post",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/PostList/layouts/ReportedPost"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "monetization_posts/posts",
        name: "monetization_posts",
        component: resolve =>
            require([
              "@/views/Pages/Moderation/MonetizationPostList/layouts/AllPostsList"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "monetization_posts/archive_posts",
        name: "monetization_archive_posts",
        component: resolve =>
            require([
              "@/views/Pages/Moderation/MonetizationPostList/layouts/ArchivePostsList"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "monetization_posts/need_approve_posts",
        name: "monetization_need_approve_posts",
        component: resolve =>
            require([
              "@/views/Pages/Moderation/MonetizationPostList/layouts/NeedApprovePostsList"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "monetization_posts/removal_posts",
        name: "monetization_removal_posts",
        component: resolve =>
            require([
              "@/views/Pages/Moderation/MonetizationPostList/layouts/RemovalRequestPostsList"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "monetization_posts/reported_posts",
        name: "monetization_reported_posts",
        component: resolve =>
            require([
              "@/views/Pages/Moderation/MonetizationPostList/layouts/ReportedPostsList"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "monetization_posts/removal_request_post/:id",
        name: "monetization_removal_request_post",
        component: resolve =>
            require([
              "@/views/Pages/Moderation/MonetizationPostList/layouts/RemovalRequestPost"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "monetization_posts/reported_post/:id",
        name: "monetization_reported_post",
        component: resolve =>
            require([
              "@/views/Pages/Moderation/MonetizationPostList/layouts/ReportedPost"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "comment_list/published_comment",
        name: "published_comments",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/CommentList/layouts/PublishedCommentList"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "comment_list/deleted_comment",
        name: "deleted_comments",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/CommentList/layouts/DeletedCommentsList"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "verification/all_verification_videos",
        name: "all_verification_videos", 
        component: resolve =>
          require([
            "@/views/Pages/Moderation/VerificationVideoList/layouts/AllVerificationVideos"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "verification/accepted_videos",
        name: "accepted_verification_videos",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/VerificationVideoList/layouts/AcceptedVerificationVideos"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "verification/declined_videos",
        name: "declined_verification_videos",
        component: resolve =>
          require([
            "@/views/Pages/Moderation/VerificationVideoList/layouts/DeclinedVerificationVideos"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "verification/video/:id",
        name: "verification_video",
        component: resolve =>
            require([
              "@/views/Pages/Moderation/VerificationVideoList/layouts/VerificationVideo"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
    ]
  },
  {
    path: "/parser/:name",
    name: "parser",
    components: {
      header: AppToolbar,
      default: resolve =>
          require(["@/views/Pages/Parser/Parser"], resolve),
      meta: {
        roles: ['Admin', 'Moderator', 'Editor'],
      }
    },
    children: [
      {
        path: "parser_post_list/posts",
        name: "parser_posts",
        component: resolve =>
            require([
              "@/views/Pages/Parser/PostList/AllPostsList"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator', 'Editor'],
        }
      },
      {
        path: "parser_post_list/parsed_post_create/:id",
        name: "parser_post_create",
        component: resolve =>
            require([
              "@/views/Pages/Parser/Post/ParsedPostCreate"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator', 'Editor'],
        }
      },
      {
        path: "parser_post_list/parsed_post_edit/:id",
        name: "parser_post_edit",
        component: resolve =>
            require([
              "@/views/Pages/Parser/Post/ParsedPostEdit"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator', 'Editor'],
        }
      },
      {
        path: "parser_post_list/archive_posts",
        name: "parser_archive_posts",
        component: resolve =>
            require([
              "@/views/Pages/Parser/PostList/ArchivePostsList"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator', 'Editor'],
        }
      },
      {
        path: "parser_post_list/need_approve_posts",
        name: "parser_need_approve_posts",
        component: resolve =>
            require([
              "@/views/Pages/Parser/PostList/NeedApprovePostsList"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator', 'Editor'],
        }
      },
      {
        path: "parser_post_list/decline_posts",
        name: "parser_decline_posts",
        component: resolve =>
            require([
              "@/views/Pages/Parser/PostList/DeclinedPostsList"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator', 'Editor'],
        }
      },
      {
        path: "parser_post_list/removal_posts",
        name: "parser_removal_posts",
        component: resolve =>
            require([
              "@/views/Pages/Parser/PostList/RemovalRequestPostsList"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator', 'Editor'],
        }
      },
      {
        path: "parser_post_list/reported_posts",
        name: "parser_reported_posts",
        component: resolve =>
            require([
              "@/views/Pages/Parser/PostList/ReportedPostsList"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator', 'Editor'],
        }
      },
      {
        path: "parser_post_list/removal_request_post/:id",
        name: "parser_removal_request_post",
        component: resolve =>
            require([
              "@/views/Pages/Parser/PostList/RemovalRequestPost"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "parser_post_list/reported_post/:id",
        name: "parser_reported_post",
        component: resolve =>
            require([
              "@/views/Pages/Parser/PostList/ReportedPost"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        },
      },
      {
        path: "enable_parser",
        name: "enable-parser",
        component: resolve =>
            require([
              "@/views/Pages/Parser/EnableParser/EnableParser"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator', 'Editor'],
        }
      },
    ]
  },
  // {
  //   path: "/parser",
  //   name: "parser",
  //   components: {
  //     header: AppToolbar,
  //     default: resolve =>
  //         require(["@/views/Pages/Parser/Parser"], resolve),
  //     meta: {
  //       roles: ['Admin', 'Moderator', 'Editor'],
  //     }
  //   },
  //   children: [
  //     {
  //       path: "parser_post_list/posts",
  //       name: "parser_posts",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/Parser/PostList/AllPostsList"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/parsed_post_create/:id",
  //       name: "parser_post_create",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/Parser/Post/ParsedPostCreate"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/parsed_post_edit/:id",
  //       name: "parser_post_edit",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/Parser/Post/ParsedPostEdit"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/archive_posts",
  //       name: "parser_archive_posts",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/Parser/PostList/ArchivePostsList"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/need_approve_posts",
  //       name: "parser_need_approve_posts",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/Parser/PostList/NeedApprovePostsList"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/decline_posts",
  //       name: "parser_decline_posts",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/Parser/PostList/DeclinedPostsList"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/removal_posts",
  //       name: "parser_removal_posts",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/Parser/PostList/RemovalRequestPostsList"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/reported_posts",
  //       name: "parser_reported_posts",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/Parser/PostList/ReportedPostsList"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/removal_request_post/:id",
  //       name: "parser_removal_request_post",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/Parser/PostList/RemovalRequestPost"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator']
  //       }
  //     },
  //     {
  //       path: "parser_post_list/reported_post/:id",
  //       name: "parser_reported_post",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/Parser/PostList/ReportedPost"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator']
  //       },
  //     },
  //     {
  //       path: "enable_parser",
  //       name: "enable-parser",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/Parser/EnableParser/EnableParser"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //   ]
  // },
  // {
  //   path: "/parser-reddit",
  //   name: "parser-reddit",
  //   components: {
  //     header: AppToolbar,
  //     default: resolve =>
  //         require(["@/views/Pages/ParserReddit/Parser"], resolve),
  //     meta: {
  //       roles: ['Admin', 'Moderator', 'Editor'],
  //     }
  //   },
  //   children: [
  //     {
  //       path: "parser_post_list/posts",
  //       name: "parser-reddit_posts",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/ParserReddit/PostList/AllPostsList"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/parsed_post_create/:id",
  //       name: "parser-reddit_post_create",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/ParserReddit/Post/ParsedPostCreate"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/parsed_post_edit/:id",
  //       name: "parser-reddit_post_edit",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/ParserReddit/Post/ParsedPostEdit"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/archive_posts",
  //       name: "parser-reddit_archive_posts",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/ParserReddit/PostList/ArchivePostsList"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/need_approve_posts",
  //       name: "parser-reddit_need_approve_posts",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/ParserReddit/PostList/NeedApprovePostsList"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/decline_posts",
  //       name: "parser-reddit_decline_posts",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/ParserReddit/PostList/DeclinedPostsList"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/removal_posts",
  //       name: "parser-reddit_removal_posts",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/ParserReddit/PostList/RemovalRequestPostsList"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/reported_posts",
  //       name: "parser-reddit_reported_posts",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/ParserReddit/PostList/ReportedPostsList"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //     {
  //       path: "parser_post_list/removal_request_post/:id",
  //       name: "parser-reddit_removal_request_post",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/ParserReddit/PostList/RemovalRequestPost"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator']
  //       }
  //     },
  //     {
  //       path: "parser_post_list/reported_post/:id",
  //       name: "parser-reddit_reported_post",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/ParserReddit/PostList/ReportedPost"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator']
  //       },
  //     },
  //     {
  //       path: "enable_parser",
  //       name: "enable-parser-reddit",
  //       component: resolve =>
  //           require([
  //             "@/views/Pages/ParserReddit/EnableParser/EnableParser"
  //           ], resolve),
  //       meta: {
  //         roles: ['Admin', 'Moderator', 'Editor'],
  //       }
  //     },
  //   ]
  // },
  {
    path: "/user-transaction-history/:id",
    name: "user-transaction-history",
    components: {
      header: AppToolbar,
      default: resolve =>
          require(["@/views/Pages/UserTransactionHistory/UserTransactionHistory"], resolve),
      meta: {
        roles: ['Admin', 'Moderator']
      }
    },
  },
  {
    path: "/transaction-detailed/:id",
    name: "transaction-detailed",
    components: {
      header: AppToolbar,
      default: resolve =>
          require([
            "@/views/Pages/TransactionDetailedPage/TransactionDetailedPage"
          ], resolve),
      meta: {
        roles: ['Admin', 'Moderator']
      }
    }
  },
  {
    path: "/transaction",
    name: "transaction",
    components: {
      header: AppToolbar,
      default: resolve =>
          require(["@/views/Pages/Transaction/Transaction"], resolve),
      meta: {
        roles: ['Admin', 'Moderator']
      }
    }
  },
  {
    path: "/settings",
    name: "settings",
    components: {
      header: AppToolbar,
      default: resolve =>
        require(["@/views/Pages/SettingsPage/SettingsPage"], resolve),
      meta: {
        roles: ['Admin', 'Moderator']
      }
    },
    children: [
      {
        path: "settings_page",
        name: "settings_page",
        component: resolve =>
          require(["@/views/Pages/SettingsPage/Settings/SettingsPage"], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "settings_meme_template",
        name: "settings_meme_template",
        component: resolve =>
          require([
            "@/views/Pages/SettingsPage/SettingsMemeTemplate/SettingsMemeTemplate"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "settings_tags",
        name: "settings_tags",
        component: resolve =>
          require([
            "@/views/Pages/SettingsPage/SettingsTags/SettingsTags"
          ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      }
    ]
  },
  {
    path: "/advertising",
    name: "advertising",
    components: {
      header: AppToolbar,
      default: resolve =>
          require(["@/views/Pages/Advertising/Advertising"], resolve),
      meta: {
        roles: ['Admin', 'Moderator']
      }
    },
    children: [
      {
        path: "active",
        name: "advertising_active",
        component: resolve =>
            require(["@/views/Pages/Advertising/AdvertisingActive"], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
      {
        path: "archived",
        name: "advertising_archived",
        component: resolve =>
            require([
              "@/views/Pages/Advertising/AdvertisingArchived"
            ], resolve),
        meta: {
          roles: ['Admin', 'Moderator']
        }
      },
    ]
  },
  {
    path: "/random_avatar",
    name: "random_avatar",
    components: {
      header: AppToolbar,
      default: resolve =>
        require(["@/views/Pages/RandomAvatar/RandomAvatar"], resolve),
      meta: {
        roles: ['Admin', 'Moderator']
      }
    }
  },
  {
    path: "/login",
    components: { default: LoginPage },
    name: "login"
  },
  {
    path: "/pages/error/404",
    components: { default: Error404 },
    name: "pages/error/Error404"
  },
  {
    path: "/pages/error/500",
    components: { default: Error500 },
    name: "pages/error/Error500"
  }
]
