export default {
  header: "Подъем",
  headerText:
    "Помощники подъема позволяют вам контролировать относительную глубину или расстояние между двумя поверхностями вдоль оси **z**. В общей сложности 25 уровней высоты. Вы можете установить подъем элемента, используя класс `elevation- {n}`, где `n` - целое число от 0 до 24, соответствующее желаемой высоте.",
  toc: [
    {
      text: "Введение",
      href: "introduction"
    },
    {
      text: "Площадка для игр",
      href: "playground"
    }
  ],
  playgroundHeader: "Площадка для игр"
}
