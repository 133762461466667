export default {
  header: "Будьте частью чего-то большего!",
  headerText:
    "Vuetify становится возможным благодаря удивительному сообществу, которое решает проблемы, создает запросы на загрузку и предоставляет бесценную обратную связь. Наша задача - создать потрясающие приложения. Много времени, вы сталкиваетесь с чем-то, что может быть сделано лучше. Возможно, вы обнаружили ошибку, или у вас есть идея для дополнительной функциональности. Замечательно! Это просто, клонируйте репозиторий Vuetify, чтобы начать работу в своей любимой среде разработки.",
  issuesHeader: "Reporting issues",
  issue1:
    "Список проблем этого репо предназначен исключительно для отчетов об ошибках и добавлении функциональности. Несоответствующие вопросы будут немедленно закрыты.",
  issue2:
    "По общим вопросам, пожалуйста, присоединитесь к [community](https://chat.vuetifyjs.com).",
  issue3: "Попробуйте найти проблему, на нее, возможно, ответили.",
  issue4: "Посмотрите, воспроизводится ли ошибка с последней версией Vuetify.",
  issue5:
    "Если воспроизводится, пожалуйста, предоставьте простой [codepen](https://template.vuetifyjs.com) или репозиторий, который можно клонировать для получения ожидаемого поведения.",
  pullRequestsHeader: "Pull requests",
  pullRequest1: "Все PR должны быть отправлены в ветвь `dev` Vuetify.",
  pullRequest2:
    "Для изменений и добавления функциональности просьба указать пример того, что вы пытаетесь решить, и пример разметки",
  pullRequest3:
    "Для исправления ошибок, пожалуйста, обратитесь к вопросу #, который PR разрешает",
  localDevHeader: "Настройка локального разработчика",
  localDevText1:
    "Vuetify содержит локальную среду разработчика, которую можно загружать для тестирования новых функций, функциональных возможностей и компонентов. Во-первых, клонируйте репозиторий:",
  localDevText2:
    "После клонирования используйте cli для <kbd> cd vuetify </kbd>. После этого в каталоге вам нужно будет создать новый файл в каталоге <kbd> dev </kbd>, который называется `Playground.vue`. Приведен пример, «Playground.example.vue», который вы можете переименовать. Имейте в виду, что этот файл не отслеживается контролем управления версиями. После копирования, вернитесь к своей ветки и запустите:",
  localDevText3:
    "Это запустит сервер node, расположенный по [http://localhost:8080](http://localhost:8080). Теперь из вашего редактора кода вы можете легко загружать компоненты и проверять все, что хотите, с молниеносной скоростью!"
}
