export default {
  header: "Alert",
  headerText:
    "Компонент `v-alert` используется для передачи важной информации пользователю. Он состоит из четырех вариантов: **success**, **info**, **warning** и **error**. У них есть назначенные иконки по умолчанию, которые можно изменить и представлять разные действия",
  components: ["v-alert"],
  examples: [
    {
      contextual: {
        header: "Контекстуальный"
      },
      closable: {
        header: "Перекрываемый",
        desc:
          "Используя `v-model` вы можете управлять состоянием Alert. Если вы не хотите назначать v-model и просто показывать предупреждение, вы можете просто присвоить значение свойство `value`."
      },
      icon: {
        header: "Иконка/Нет иконки",
        desc:
          "Вы можете легко назначить пользовательский значок или удалить все вместе."
      },
      transition: {
        header: "Отображение перехода",
        desc:
          "Вы можете применить пользовательский переход показать/скрыть предупреждения."
      },
      outline: {
        header: "Контур",
        desc:
          "Очерченные предупреждения наследуют свои границы от текущего применяемого цвета."
      }
    }
  ],
  props: {
    dismissible: "Указывает, что предупреждение может быть закрыто",
    icon: "Назначает конкретную иконку",
    outline: "У оповещения будет контур",
    type:
      "Укажите оповещение **success**, **info**, **warning** или **error**. Использует контекстный цвет и предопределенную иконку."
  }
}
