export default {
  header: "Нижняя навигация",
  headerText:
    " `V-bottom-nav` является альтернативой боковой панели. Он в основном используется на мобильных устройствах и поставляется в двух вариантах: значки и текст, а также сдвиг.",
  components: ["v-bottom-nav"],
  examples: [
    {
      iconsAndText: {
        header: "Иконки и текст",
        desc:
          "Хотя нижняя навигация предназначена для использования с `vue-router`, вы также можете программно управлять активным состоянием кнопок с помощью `active.sync`. Вы можете изменить значение кнопки, используя свой атрибут `value`."
      },
      colorAndShift: {
        header: "Цвет и переключение",
        desc:
          "При использовании цветного фона рекомендуется использовать свойство `light`. Свойство `shift` скроет текст кнопки до тех пор, пока не будет активна. Имейте в виду, что текст `v-btn` должен быть обернут тегом `<span>` ."
      },
      toggle: {
        header: "Переключатель",
        desc:
          "Как и другие компоненты Vuetify, вы можете управлять состоянием отображения с помощью `v-model`."
      }
    }
  ],
  props: {
    active:
      "Удерживает значение текущей активной кнопки. Если кнопка не имеет значения, вместо этого будет использоваться его индекс. Эта поддержка поддерживает модификатор **.sync**.",
    absolute: "Mixins.Positionable.props.absolute",
    fixed: "Mixins.Positionable.props.fixed"
  },
  events: {
    "update:active": "Событие, используемое для `active.sync`"
  }
}
