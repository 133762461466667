import Vue from "vue"
import { populatesErrorBags } from "../utils/response"
import VeeValidate, {
  ValidationProvider,
  ValidationObserver
} from "vee-validate"
import InteractsWithUserAccess from "../concerns/InteractsWithUserAccess"
import VueLS from "vue-ls"
import VueBus from "vue-bus"
import ValidationTextField from "../components/inputs/ValidationTextField"
import ValidationTextArea from "../components/inputs/ValidationTextArea"

Vue.use(VeeValidate) 
Vue.use(VueLS)
Vue.use(VueBus)

Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

Vue.component(ValidationTextField.name, ValidationTextField)
Vue.component(ValidationTextArea.name, ValidationTextArea)

Vue.mixin(populatesErrorBags)
Vue.mixin(InteractsWithUserAccess)
