import Alerts from "./Alerts"
import ApiExplorer from "./ApiExplorer"
import Autocompletes from "./Autocompletes"
import Avatars from "./Avatars"
import Badges from "./Badges"
import BottomNavigation from "./BottomNavigation"
import BottomSheets from "./BottomSheets"
import Breadcrumbs from "./Breadcrumbs"
import ButtonGroups from "./ButtonGroups"
import Buttons from "./Buttons"
import Cards from "./Cards"
import Carousels from "./Carousels"
import Chips from "./Chips"
import Combobox from "./Combobox"
import DataIterator from "./DataIterator"
import DataTables from "./DataTables"
import DatePickers from "./DatePickers"
import Dialogs from "./Dialogs"
import Dividers from "./Dividers"
import ExpansionPanels from "./ExpansionPanels"
import FloatingActionButtons from "./FloatingActionButtons"
import Footer from "./Footer"
import Forms from "./Forms"
import HelpMeChoose from "./HelpMeChoose"
import Hover from "./Hover"
import Icons from "./Icons"
import Images from "./Images"
import Inputs from "./Inputs"
import ItemGroups from "./ItemGroups"
import Jumbotrons from "./Jumbotrons"
import Lists from "./Lists"
import Menus from "./Menus"
import NavigationDrawers from "./NavigationDrawers"
import OverflowBtns from "./OverflowBtns"
import Paginations from "./Paginations"
import Parallax from "./Parallax"
import Progress from "./Progress"
import Ratings from "./Ratings"
import SelectionControls from "./SelectionControls"
import Selects from "./Selects"
import Sliders from "./Sliders"
import Snackbars from "./Snackbars"
import Steppers from "./Steppers"
import Subheaders from "./Subheaders"
import Tabs from "./Tabs"
import TemplateList from "./TemplateList"
import Textarea from "./Textarea"
import TextFields from "./TextFields"
import Timelines from "./Timelines"
import TimePickers from "./TimePickers"
import Toolbars from "./Toolbars"
import Tooltips from "./Tooltips"
import Windows from "./Windows"
import Treeview from "./Treeview"
export default {
  Alerts,
  ApiExplorer,
  Autocompletes,
  Avatars,
  Badges,
  BottomNavigation,
  BottomSheets,
  Breadcrumbs,
  ButtonGroups,
  Buttons,
  Cards,
  Carousels,
  Chips,
  Combobox,
  DataIterator,
  DataTables,
  DatePickers,
  Dialogs,
  Dividers,
  ExpansionPanels,
  FloatingActionButtons,
  Footer,
  Forms,
  HelpMeChoose,
  Hover,
  Icons,
  Images,
  Inputs,
  ItemGroups,
  Jumbotrons,
  Lists,
  Menus,
  NavigationDrawers,
  OverflowBtns,
  Paginations,
  Parallax,
  Progress,
  Ratings,
  SelectionControls,
  Selects,
  Sliders,
  Snackbars,
  Steppers,
  Subheaders,
  Tabs,
  TemplateList,
  Textarea,
  TextFields,
  Timelines,
  Treeview,
  TimePickers,
  Toolbars,
  Tooltips,
  Windows
}
