export default {
  props: {
    actions: "在选取器中使用操作项时使用它",
    autosave:
      "自动保存选定的值。这将更新内部先前的值，所以如果单击取消，它将不会恢复用户更改。",
    headerColor:
      "定义标题颜色。如果未指定，将使用由<code>颜色（color）</code>属性或默认选取器颜色定义的颜色。",
    landscape: "Orients picker horizontal",
    noTitle: "隐藏选取器标题",
    scrollable: "允许在拾取器中使用鼠标滚轮",
    value: "选取器的值"
  }
}
