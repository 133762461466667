import Events from "./Events"
import Pages from "./Pages"
import Params from "./Params"
import Props from "./Props"
import Slots from "./Slots"
import Types from "./Types"

export default {
  Events,
  Pages,
  Params,
  Props,
  Slots,
  Types
}
