export default {
  header: "Прогресс",
  headerText:
    "Компоненты `v-progress-circular` и `v-progress-linear` используются для визуального отображения передачи данных пользователям. Они также могут представлять неопределенность, такую как загрузка или обработка. Эти компоненты содержат слот, который расположен по центру в контейнере компонента.",
  components: ["v-progress-circular", "v-progress-linear"],
  examples: [
    {
      circularDefault: {
        header: "По умолчанию",
        desc:
          "По умолчанию, круговой прогресс бар использует вторичный цвет приложения."
      },
      circularColored: {
        header: "Цветной",
        desc: "Могут применяться альтернативные цвета."
      },
      circularIndeterminate: {
        header: "Неопределенный",
        desc: "Неопределенный прогресс бар всегда выполняется."
      },
      circularSizeAndWidth: {
        header: "Размер и ширина",
        desc:
          "Компонент кругового прогресс бара может изменять ширину и размер."
      },
      circularRotate: {
        header: "Поворот",
        desc: "Начало прогресс бара может быть повернуто."
      },
      linearDeterminate: {
        header: "Определенный",
        desc:
          "Компонент линейного прогресс бара может иметь заданное состояние, модифицированное `v-model`."
      },
      linearIndeterminate: {
        header: "Неопределенный",
        desc:
          "Как и в случае с круговым прогресс баром, линейный имеет также неопределенное состояние."
      },
      linearBuffer: {
        header: "Буфер",
        desc:
          "Состояние буфера представляет два значения одновременно. Основное значение контролируется моделью, тогда как буфер управляется значением свойства `buffer-value`."
      },
      linearQueryIndeterminateAndDeterminate: {
        header: "Запрос неопределенный и выполненный",
        desc:
          "Состояние управляется правдивостью неопределенного с запросом свойства, установленным в true."
      },
      linearCustomHeightAndContextualColors: {
        header: "Пользовательская высота и контекстные цвета",
        desc:
          "Пользовательская высота или контекстный цвет могут быть применены к индикатору выполнения. Основной цвет - это основной цвет вашего приложения."
      },
      linearCustomColors: {
        header: "Пользовательские цвета",
        desc:
          "Вы также можете установить цвет с помощью реквизита `color` и `background-color`."
      }
    }
  ]
}
