export default {
  header: `Что происходит`,
  headerText:
    "Vuetify всегда разрабатывается, работает над улучшением существующей базы кода, а также добавляет новые функции и функциональные возможности, которые помогают упростить создание приложений. Ниже приведен примерный список запланированных функций. Это не исчерпывающий список и может быть изменен в любое время без предварительного уведомления. Этот список может быть изменен без предварительного уведомления.",
  roadmap: [
    {
      icon: "mdi-alert-decagram",
      name: "Version 1.0",
      date: "Q4 2017",
      desc:
        "Официальный релиз Vuetify. Будет включать полное обновление документации с поддержкой i18n, светлые / темные встроенные примеры и многое другое!"
    },
    {
      icon: "brush",
      name: "Alpha theme",
      date: "Q1 2018",
      desc: `Первая тема Vuetify. Предмет дизайна материалов ручной работы с Vuetify по своей сути. Особенности пользовательских компонентов.`
    },
    {
      icon: "error",
      name: "Version 1.1",
      date: "Q1 2018",
      desc:
        "Будет сосредоточено на обновлении / улучшении и абстрагировании элементов формы. Новые абстрактные компоненты будут общедоступными, чтобы разработчики могли легче создавать свои собственные реализации."
    },
    {
      icon: "mdi-package-variant-closed",
      name: "Front-end pack",
      date: "Q2 2018",
      desc:
        "Включает 10 новых компонентов. Будет **бесплатным** всем благодаря нашим Покровителям."
    },
    {
      icon: "error",
      name: "Version 1.2",
      date: "Q2 2018",
      desc: "TBD"
    },
    {
      icon: "mdi-package-variant-closed",
      name: "Back-end pack",
      date: "Q2 2018",
      desc:
        "Включает 10 новых компонентов. Будет **бесплатным** всем благодаря нашим Покровителям."
    },
    {
      icon: "mdi-youtube-play",
      name: "Vueticasts",
      date: "2018",
      desc:
        "Видео-серия Vue и Vuetify. Будет **бесплатным** всем благодаря нашим Покровителям."
    }
  ]
}
