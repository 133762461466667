export default {
  api: "API",
  default: "Default",
  description: "Description",
  examples: "Examples",
  howItWorks: "How it works",
  name: "Name",
  openInCodePen: "Open in Codepen",
  options: "Options",
  props: "Props",
  showExample: "Show Example",
  slots: "Slots",
  supplemental: "Supplemental",
  type: "Type",
  toc: [
    {
      text: "Introduction",
      href: "introduction"
    },
    {
      text: "Usage",
      href: "usage"
    },
    {
      text: "API",
      href: "api"
    },
    {
      text: "Supplemental",
      href: "supplemental"
    },
    {
      text: "Examples",
      href: "examples"
    }
  ],
  usage: "Usage",
  parameters: "Parameters",
  return: "Returns",
  example: "Example",
  signature: "Signature",
  value: "Value"
}
