export default {
  header: "Content",
  headerText:
    "Vuetify はいくつかの基本的な要素に対してカスタムスタイルを適用します。",
  blockquoteHeader: "引用 (blockquote タグ)",
  paragraphsHeader: "段落 (p タグ)",
  codeHeader: "コード (code タグ)",
  codeText: "これはインラインの `<code>` タグのサンプルです。",
  variablesHeader: "変数 (var タグ)",
  variablesText: "<var>v</var> = <var>u</var> * <var>e</var>",
  userInputHeader: "ユーザー入力 (kbd タグ)",
  userInputText:
    "Vuetifyをインストールするには、コンソールに<kbd>npm install vuetify</kbd>と入力してください。完了したら<kbd>cd `<project name>`</kbd> と入力し、<kbd>npm install</kbd> を実行してください。"
}
