import Additional from "./additional"
import Components from "./components"
import Directives from "./directives"
import Framework from "./framework"
import Generic from "./generic"
import GettingStarted from "./getting-started"
import Guides from "./guides"
import Layout from "./layout"
import Meta from "./meta"
import Mixins from "./mixins"
import Motion from "./motion"
import Style from "./style"
import Vuetify from "./vuetify"

import Vuse from "./vuse"
import validation from "./validation"
import messages from "./messages"
import labels from "./labels"
export default {
  Additional,
  Components,
  Directives,
  Framework,
  Generic,
  GettingStarted,
  Guides,
  Layout,
  Meta,
  Mixins,
  Motion,
  Style,
  Vuetify,
  Vuse,
  validation,
  messages,
  labels
}
