export default {
  app:
    "Назначает компонент как часть макета приложения. Используется для динамической настройки размера содержимого",
  auto: "Список центров по выбранному элементу",
  centered: "Центры вкладок",
  clearable:
    "Добавить функциональные возможности ввода, значок по умолчанию - Material Icons **clear*",
  dontFillMaskBlanks:
    "Отключает автоматическое отображение символов при наборе текста",
  falseValue: "Устанавливает значение для фальсифицированного состояния",
  fullWidth: "Форсировать 100% ширины",
  fixedTabs: "`v-tabs-item` min-width 160px (72px mobile), max-width 264px",
  height: "Установка высоты компонента",
  id: "Устанавливает id DOM компонента",
  mask:
    "Примените специальную маску на вход. Для получения дополнительной информации см. Таблицу масок выше",
  max: "Устанавливает максимальную ширину",
  maxHeight: "Устанавливает максимальную высоту для содержимого",
  min: "Устанавливает минимальную ширину",
  mobileBreakPoint: "Задает установленную контрольную точку mobile",
  overlap: "Компонент будет перекрывать содержимое",
  persistent: "Клик снаружи не отменяет диалог",
  returnMaskedValue: "Возвращает неизмененную строку в маске",
  ripple: "Применяет директиву `v-ripple`",
  shift: "Скрыть текст кнопки, если не активен",
  singleLine: "Ярлык не перемещается на фокус/dirty",
  size: "Устанавливает высоту и ширину элемента",
  textColor: "Применяет заданный цвет к управляющему тексту",
  tile: "Удаляет радиус границы",
  trueValue: "Устанавливает значение для правдивого состояния",
  value: "Контроль видимости",
  width: "Ширина содержимого"
}
