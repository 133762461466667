export default {
  props: {
    absolute: "给元素设置绝对定位",
    bottom: "将组件向底部对齐",
    fixed: "给元素设置固定定位",
    left: "将组件向左边对齐",
    right: "将组件向右边对齐",
    top: "将组件向顶部对齐"
  }
}
