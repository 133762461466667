export default {
  app: "指定组件作为应用程序布局的一部分，用于动态调整内容大小。",
  auto: "在所选择的元素上居中列表",
  centered: "将选项卡居中",
  clearable: "添加输入框清除功能，默认图标是 Material Icons **clear**",
  dontFillMaskBlanks: "键入时禁用自动字符显示",
  falseValue: "设置虚假状态的值",
  fullWidth: "Forces 100% width",
  fixedTabs: "`v-tabs-item` min-width 160px (72px mobile), max-width 264px",
  height: "设置组件高度",
  id: "设置组件上的DOM id",
  mask: "将自定义字符掩码应用于输入框。有关更多信息，请参阅上面的掩码表格。",
  max: "设置最大宽度",
  maxHeight: "设置内容的最大高度",
  min: "设置最小宽度",
  mobileBreakPoint: "设置指定的移动端断点",
  overlap: "组件将折叠插槽里的内容",
  persistent: "点击外部不会消除对话框",
  returnMaskedValue: "返回未修改的掩码字符串",
  ripple: "使用`v-ripple`指令",
  shift: "不是激活状态时隐藏按钮上的文字",
  singleLine: "Label does not move on focus/dirty",
  size: "设置元素的高度和宽度",
  textColor: "将指定的颜色应用于控件文本",
  tile: "删除边看弧度",
  trueValue: "设置真值",
  value: "控制可见性",
  width: "内容的宽度"
}
