export default {
  header: "List",
  headerText:
    "`v-list` компонент используется для отображения информации. Он может содержать аватар, контент, действия, подзаголовки и многое другое. Списки могут содержать детей и использоваться на боковой панели(sidebar).",
  components: [
    "v-list",
    "v-list-tile",
    "v-list-tile-title",
    "v-list-tile-sub-title",
    "v-list-tile-content",
    "v-list-tile-action",
    "v-list-tile-avatar",
    "v-list-group",
    "v-list-tile-action-text"
  ],
  examples: [
    {
      avatarTwoLines: {
        header: "Аватар с 2 строчками",
        desc:
          "Списки могут принимать массив элементов списка. Когда задан массив, компонент списка будет определять классы, которые необходимы в зависимости от того, что ему было дано. Вы также можете определить заголовки или разделители в массиве items."
      },
      avatarTitleAndAction: {
        header: "Аватар с заголовком и действием",
        desc:
          "Списки также содержат слоты для более четкого подхода. Если вы выберете этот подход, помните, что вы должны предоставить дополнительные пропсы для правильного интервала. В этом примере у нас есть плитка с аватаром, поэтому мы должны предоставить свойство `avatar`."
      },
      iconTwoLinesAndAction: {
        header: "Иконка с 2 строками и действием",
        desc:
          "Списки могут содержать подзаголовки, разделители и могут содержать 1 или более строк. Подзаголовок будет дополняться с помощью многоточия, если он не вмещается в одну строку."
      },
      avatarThreeLines: {
        header: "Аватар с 3 строчками",
        desc:
          "Для трех линейных списков, субтитры будут зажимать вертикально на 2 строки, а затем на многоточие. Если вам требуется более 3 строк, рекомендуется использовать [card](/components/cards)."
      },
      avatarSubheaderTitleAndAction: {
        header: "Аватар с заголовком и действием",
        desc:
          "Когда используется слот списков, вы должны вручную определить, содержит ли он заголовки, или если элементы содержат аватар. Это необходимо для поддержания правильного расстояния."
      },
      subheadingsAndDividers: {
        header: "Подзаголовки и разделители",
        desc: "Списки могут содержать несколько подзаголовков и разделителей."
      },
      cardList: {
        header: "Изображение карты с панелью инструментов и списком",
        desc: "Список можно комбинировать с картой."
      },
      titleSubtitleActionsAndActionText: {
        header: "Заголовок с _sub-title_, _actions_ и _action-text_",
        desc:
          "Список может содержать стек внутри действия. _Ripple_ и _router_ пропсы могут передаваться через главный `v-list`, в `v-list-tile` или как свойство в массиве items."
      },
      actionTitleAndSubtitle: {
        header: "Действие с заголовком и подзаголовком",
        desc: "Список может содержать до 3 строк."
      },
      expansionLists: {
        header: "Расширение списков",
        desc:
          "Список может содержать группу элементов, которые будут отображаться при нажатии. Расширение списков также используются в компоненте `navigation drawer`."
      }
    }
  ],
  props: {
    avatar:
      "Используется для установки минимальной высоты плитки в элементе списка с одной строкой",
    dense: "Уменьшает максимальную высоту списков",
    expand: "Будет только скрываться при явном закрытии",
    subheader:
      "Удаляет верхний padding. Используется, когда предыдущий sibling является заголовком",
    threeLine: "Увеличивает высоту списка для трех линий",
    twoLine: "Увеличивает высоту списка для двух линий",
    inactive:
      "Если установлено, плитка списка не будет отображаться как ссылка, даже если она должна to/href prop или обработчик @click"
  }
}
