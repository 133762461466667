export default {
  header: "Часто задаваемые вопросы",
  headerText:
    "Застрял на конкретной проблеме? Проверьте некоторые из этих распространенных ошибок, прежде чем создавать вопрос. Если вы все еще не можете найти то, что ищете, отправьте [вопрос](https://issues.vuetifyjs.com) на github или попросите сообщество в [discord](https://chat.vuetifyjs.com).",
  question: "Вопрос:",
  answer: "Ответ",
  noResultsFound: "результатов не найдено",
  resetSearch: "Сбросить поиск",
  gotchas: [
    {
      q: "Мой код не работает - что мне делать?",
      a:
        "Во-первых, убедитесь, что вы используете последнюю версию Vue.js и Vuetify. Попробуйте воспроизвести его в codepen, используя следующий [шаблон](https://template.vuetifyjs.com/). Если вы не можете воспроизвести проблему вне своей среды, это обычно означает, что проблема возникает локально. Если вы все еще не можете решить свою проблему, предоставьте код и введите **#need-help** в [community](https://chat.vuetifyjs.com)."
    },
    {
      q:
        "Я получаю ошибку, подобную следующей: <br>Error in ./node_modules/vuetify/src/dir/file.js Module parse failed: Unexpected token (&lt;lineno&gt;:&lt;characterno&gt;)",
      a:
        "Если вы используете IDE, например IntelliJ IDEA или WebStorm, он часто добавляет автоматический импорт, указывающий на каталог `vuetify/src/` для компонентов, которые вы используете. Измените путь импорта с `vuetify/src/` на `vuetify/es5/`"
    },
    {
      q: "Есть ли codepen шаблон с маршрутизатором?",
      a: "да, [right here](https://codepen.io/zikeji/pen/ypeQNm)."
    },
    {
      q: "Мое приложение выглядит неправильно",
      a:
        "Vuetify требует использования компонента `v-app`. Он должен обернуть все ваше приложение и стать центральной точкой для большинства функциональных возможностей фреймворка. Если по какой-либо причине вы не можете использовать этот элемент, вы можете имитировать его из атрибутов и классов. Установите для атрибута `data-app` значение true для самого высокого доступного элемента (не включая body) и **application application - {light | dark}** классов."
    },
    {
      q: "Тема Dark или Light не работает.",
      a:
        'Vuetify требует точки монтирования для выполнения таких задач, как стилизация темы. Убедитесь, что у вас есть приложение `v-app`. В случае, если это невозможно, по какой-либо причине вы можете имитировать свое поведение, применяя **data-app** и **class = "application application - light (или dark)** к самому элементу родителю, который в вашем приложении.'
    },
    {
      q: "Меню/Диалог/Панель навигации не открывается должным образом.",
      a:
        "Убедитесь, что ваши компоненты обернуты элементом `v-app`. Если вы используете элемент для активации компонента, который не помещен в слот <kbd> activator </kbd>, убедитесь, что вы прекратили распространение события щелчка. Эти компоненты используют директиву `v-external-click` и немедленно закрываются."
    },
    {
      q:
        "Полоса прокрутки показывается, хотя мой контент не переполняется вертикально.",
      a:
        "Vuetify по умолчанию включает панель прокрутки html. Это выбор дизайна и неоднократно обсуждался. Есть плюсы и минусы того, что они имеют и не имеют этого, и на данный момент голосование за то, чтобы оставить его как есть. Если вы хотите отключить эту функцию, просто добавьте `html {overflow-y: auto}` в ваш файл стиля."
    },
    {
      q: "Как центрировать вертикально?",
      a:
        "Примените **fill-height** prop к `v-container`. Этот класс-помощник обычно добавляет только **height: 100%**, но для контейнера он также ищет дочерний `v-layout` и применяет необходимые классы для вертикального центрирования содержимого."
    },
    {
      q: "Моя _/_ ссылка активна, когда я на _/home_ странице",
      a:
        "Добавьте **exact** к ссылке, которая указывает на абсолютный /. Для получения дополнительной информации об этом вы можете посетить официальный маршрутизатор Vue [документация](https://router.vuejs.org/en/api/router-link.html)."
    },
    {
      q: "Моя страница на мобильном телефоне не реагирует",
      a:
        'Добавьте `<meta name=" viewport "content=" width=device-width, initial-scale=1, maximum-scale=1, user-scaleable=no, minimal-ui">` в `<head>` в вашем index.html.'
    },
    {
      q:
        "Как я могу использовать шрифты Awesome Icons или Material Design Icons (mdi)?",
      a:
        "Вы должны добавить шрифты в свой index.html или иным образом импортировать их в свой проект <br> **MDI**: [Material Design Icons](https://materialdesignicons.com/getting-started) <br> **FA**: [Font Awesome](http://fontawesome.io/get-started/)"
    },
    {
      q: "Мой диалог закрывается сразу после нажатия кнопки",
      a:
        'Если вы не используете слот **activator** для `v-menu` и` v-dialog`, вы должны вручную остановить _propagation_ события click. Для этого просто добавьте модификатор _.stop_ в событие click, `@click.stop ="myMethod"`.'
    },
    {
      q: "Относительные изображения не работают в компонентах `v-card`",
      a:
        "Vue loader автоматически преобразует относительные пути в требуемые функции. К сожалению, это не тот случай, когда речь идет о пользовательских компонентах. Вы можете обойти эту проблему, используя `require`. \n\n `<v-card: src=\"require('path/to/img/img.jpg')\"`"
    }
  ],
  questionHeader: "Есть что-то, что, по-твоему, здесь нет?",
  questionButton: "Дайте нам знать"
}
