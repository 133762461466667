export default {
  props: {
    activeClass:
      '컴포넌트가 활성화(active)되었을때 적용되는 클래스 **경고** 컴포넌트에 따라 사이드 이펙트가 있을 수 있습니다. 커스텀 클래스를 추가하는 방법은 다음 과 같음 `active-class="default-class your-class"` ',
    append: "Vue 라우터의 router-link prop",
    disabled: "라우트 아이템이 비활성화됨(disabled)",
    exact: '링크를 정확히 매치. 설정되지 않으면 "/"는 모든 라우트와 매치됨',
    exactActiveClass: "Vue 라우터의 router-link prop",
    href: "컴포넌트의 태그가 `<a>`가 됩니다..",
    nuxt: "링크를 nuxt-link로 지정",
    replace: "Vue 라우터의 router-link prop",
    to: "컴포넌트 태그가 `<router-link>`가 됩니다.",
    tag: "컴포넌트 태그를 커스텀 태그로",
    target: "target 어트리뷰트를 설정. 앵커(anchor) 태그의 경우만 작동합니다."
  }
}
