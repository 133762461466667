import Resizing from "./Resizing"
import Ripples from "./Ripples"
import Scrolling from "./Scrolling"
import TouchSupport from "./TouchSupport"

export default {
  Resizing,
  Ripples,
  Scrolling,
  TouchSupport
}
