export default {
  header: "Кнопка",
  headerText:
    "Компонент `v-btn` заменяет стандартную кнопку html темой material design и множеством опций. Любой цвет вспомогательного класса может использоваться для изменения фона или текста.",
  components: ["v-btn"],
  examples: [
    {
      usage: {
        header: "Использование",
        desc: ""
      },
      flat: {
        header: "Flat - плоские",
        desc:
          "Flat кнопки не имеют теней и фона. Только при наведении - это контейнер для показанной кнопки."
      },
      raised: {
        header: "Raised - обычные",
        desc:
          "Raised кнопки имеют тень, которая увеличивается при нажатии. Это стиль по умолчанию."
      },
      depressed: {
        header: "Depressed - нажатые",
        desc:
          "Нажатые кнопки по-прежнему сохраняют свой цвет фона, но не имеют тени."
      },
      dropdown: {
        header: "Варианты раскрывающегося списка кнопок",
        desc:
          "Выпадающие кнопки являются регулярными выбором с дополнительным стилем.",
        uninverted: true
      },
      toggle: {
        header: "Кнопка Toggle",
        desc:
          "В зависимости от выбранных свойств кнопки Toggle - это по существу стилизованные радио или флажки. Этот компонент совместим с компонентом `v-toolbar`."
      },
      icon: {
        header: "Иконка",
        desc: "Иконки могут использоваться для основного содержимого кнопки."
      },
      floating: {
        header: "Плавающая",
        desc: "Плавающие кнопки круглые и обычно содержат значок."
      },
      loaders: {
        header: "Погрузчики",
        desc:
          "Используя загрузку, вы можете уведомить пользователя о том, что происходит обработка. По умолчанию используется компонент `v-progress-circle`, но это можно настроить."
      },
      sizing: {
        header: "Определение размеров",
        desc:
          "Кнопкам можно задавать различные параметры выбора, чтобы соответствовать множеству сценариев."
      },
      outline: {
        header: "Контур",
        desc:
          "Контур кнопки наследуют свои границы от текущего применяемого цвета."
      },
      round: {
        header: "Круглый",
        desc:
          "Закругленные кнопки ведут себя так же, как обычные кнопки, но имеют закругленные края."
      },
      block: {
        header: "Блок",
        desc: "Кнопки блока расширяют всю доступную ширину."
      }
    }
  ],
  props: {
    "v-btn": {
      block: "Расширяет кнопку до 100% доступного пространства",
      depressed: "Удаляет тень кнопки",
      fab: "Делает кнопку круглой",
      flat: "Удаляет цвет фона кнопки",
      icon: "Назначает кнопку в виде значка - круглый и плоский",
      inputValue: "Управляет активным состоянием кнопки",
      large: "Кнопка большого размера",
      loading: "Добавляет анимацию иконок загрузки",
      outline: "Кнопка будет иметь контур",
      round: "Кнопка будет круглой по бокам",
      small: "Кнопка малого размера",
      type: "Установите атрибут типа кнопки"
    }
  }
}
