export default {
  header: "Бесплатные и премиальные темы",
  headerText:
    "Vuetify предлагает бесплатные и премиальные готовые темы, предназначенные для мгновенного запуска. Бесплатные темы доступны для установки через vue-cli, или вы можете просто загрузить исходники.",
  buy: "Информация и демо",
  free: "Бесплатно",
  demo: "ДЕМОНСТРАЦИЯ",
  comingSoon: "Скоро",
  sourceCode: "Исходный код",
  templates: [
    {
      screenshot: "/static/doc-images/starter/vuetify-premium.jpg",
      title: "Startup",
      tag: "PREMIUM",
      description:
        "Покажите свою компанию или портфолио с помощью этого красиво оформленного шаблона Material design.",
      price: "25$",
      demoUrl: ""
    },
    {
      screenshot: "/static/doc-images/starter/vuetify-parallax-starter.png",
      title: "Parallax",
      description:
        "Этот красивый параллакс с одной страницей - отличная домашняя страница для любого приложения.",
      sourceUrl: "vuetifyjs/parallax-starter",
      demoUrl: "/themes/parallax-starter"
    },
    {
      screenshot: "/static/doc-images/starter/vuetify-blog-starter.png",
      title: "Блог",
      description:
        "Простой шаблон, который содержит чистый интерфейс для создания блога или приложения, подобного блогу.",
      sourceUrl: "vuetifyjs/blog-starter",
      demoUrl: "/themes/blog-starter"
    }
  ]
}
