import { toLeadingZero } from "../helpers"

export class MaxDurationValidator {
  /**
   * Get validation message
   *
   * @param field
   * @param args
   * @return {string} 
   */
  getMessage(field, args) {
    const maxDuration = parseInt(args[0])
    const hours = Math.floor(maxDuration / 60)
    const minutes = maxDuration - hours * 60

    return `The ${field} has to be less than ${toLeadingZero(
      hours
    )}:${toLeadingZero(minutes)}`
  }

  /**
   * Validate duration
   *
   * @param value
   * @param args
   * @return {boolean}
   */
  validate(value, args) {
    const maxDuration = parseInt(args[0])

    return value <= maxDuration
  }
}
