export default {
  header: "内容",
  headerText: "Vuetify拥有多个标准元素的自定义样式",
  blockquoteHeader: "引用块",
  paragraphsHeader: "段落",
  codeHeader: "代码`<code>`",
  codeText: "这是一个内联`<code>`元素示例.",
  variablesHeader: "变量`<var>`",
  variablesText: "<var>v</var> = <var>u</var> * <var>e</var>",
  userInputHeader: "用户输入",
  userInputText:
    "要安装Vuetify，在控制台键入<kbd>npm install vuetify</kbd>。完成后，再键入<kbd>cd `<project name>`</kbd>并且执行<kbd>npm install</kbd>"
}
