import Vue from "vue"
import Vuex from "vuex"
import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"
import navigation from "./modules/navigation"
import swatch from "./modules/swatch"
import user from "./modules/user"
import reason from "./modules/reason"
import languages from "./modules/languages"
import removalRequest from "./modules/removalRequest"
import countries from "./modules/countries"
import counterNew from "./modules/counterNew"
import tags from "./modules/tags"

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {},
  getters,
  mutations,
  actions,
  modules: {
    navigation,
    swatch,
    user,
    reason,
    languages,
    removalRequest,
    countries,
    counterNew,
    tags
  }
})
