export default {
  Any: "Any",
  Array: "Array",
  Boolean: "Boolean",
  Function: "Function",
  Number: "Number",
  Object: "Object",
  String: "String",
  undefined: "undefined"
}
