export default {
  methods: {
    /**
     * Open notification
     *
     * @param payload
     */
    $notificationOpen(payload) {
      this.$bus.emit("notifications.open", payload)
    },
 
    /**
     * Close notification
     */
    $notificationClose() {
      this.$bus.emit("notifications.close", {})
    },

    /**
     * Shows response error
     *
     * TODO: Rename method
     *
     * @param error
     */
    $notificationHandleResponseError(error) {
      /*
       * Log regular exceptions
       */
      if (error && !error.response) {
        this.$notificationOpen({
          content: error.toString(),
          props: {
            timeout: 6000,
            color: "red"
          }
        })

        throw error
      }

      /*
       * Exit in case error has no response
       * or is already notified to user
       */
      if (!error || !error.response || error.isNotified) {
        return
      }

      const message = error.response.data.message

      this.$notificationOpen({
        content: message,
        props: {
          timeout: 5000,
          color: "red"
        }
      })
    },

    /**
     * Show notification message
     *
     * @param message
     * @param timeout
     */
    $notificationShow(message, timeout = 4000) {
      this.$notificationOpen({
        content: message,
        props: {
          timeout: timeout,
          color: "info"
        }
      })
    }
  }
}
