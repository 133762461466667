import { flatten, arrayPluck } from "../../utils/helpers"

const state = {
  user: null,
  userInfo: null
}

const getters = { 
  user: state => state.user,
  getUserInfoAndPermissions: state => state.userInfo,
  permissions: state =>
    state.user ? flatten(arrayPluck(state.user.roles, "permissions")) : []
}

export const types = {
  SET_AUTH_USER: "SET_AUTH_USER",
  SET_USER_INFO: "SET_USER_INFO"
}

const mutations = {
  [types.SET_AUTH_USER](state, data) {
    state.user = data
  },
  [types.SET_USER_INFO](state, data) {
    state.userInfo = data
  }
}

const actions = {}

export default {
  state,
  actions,
  getters,
  mutations
}
