export default {
  header: "Панель навигации",
  headerText:
    "`v-navigation-drawer`Компонент - это то, что ваши пользователи будут использовать для навигации по приложению. Панель навигации предварительно настроена для работы с **vue-router** прямо из коробки.",
  components: ["v-navigation-drawer"],
  examples: [
    {
      permanent: {
        header: "По умолчанию",
        desc:
          "Панель навигации в основном используется для размещения ссылок на страницы вашего приложения"
      },
      permanentClipped: {
        header: "Цвета панели",
        desc:
          "Панель навигации может быть настроена в соответствии с дизайном любого приложения. Хотя любой компонент может использоваться в панели, основными из них будут `v-list`, все дочении компоненты **list** и `v-divider`"
      },
      permanentFloating: {
        header: "Постоянно плавающая панель",
        desc:
          "Панель навигации может быть размещен внутри карты и плавать над фоновым контентом."
      },
      persistent: {
        header: "Аватары",
        desc:
          "Поскольку панель поддерживает компонент `v-list`, вы можете легко создавать индивидуальные решения для панели мониторинга."
      },
      mini: {
        header: "Mini",
        desc:
          "В навигационной панели также есть мини-вариант, который можно управлять с помощью prop `mini-variant.sync`."
      },
      temporary: {
        header: "Временный",
        desc:
          "Временная панель находиться над своим приложением и использует холст (наложение), чтобы затемнить фон. Это поведение панели подражает нативной панели меню на мобильном устройстве. Щелчок за пределами ящика приведет к его закрытию."
      },
      dark: {
        header: "Темная тема",
        desc:
          "Vuetify также поддерживает темную тему приложения. Это не изменит компоненты, которые имеют темы по умолчанию, поэтому в некоторых случаях необходимо вручную установить темные темы.",
        uninverted: true
      }
    }
  ],
  props: {
    absolute: "Mixins.Positionable.props.absolute",
    clipped: "Обрезанный ящик находится под панелью инструментов приложения",
    disableResizeWatcher:
      "Будет автоматически открывать/закрывать панель при изменении размера в зависимости от мобильного или desktop.",
    disableRouteWatcher:
      "Отключает открытие навигационной панели при изменении route",
    fixed: "Mixins.Positionable.props.fixed",
    floating: "Плавающая панель не имеет видимого контейнера (без границ)",
    hideOverlay: "Скрыть отображение наложения",
    miniVariantWidth:
      "Определяет ширину, назначенную при включении prop `mini`",
    miniVariant:
      "Condenses ширина панели навигации, также принимает модификатор **.sync **. При этом панель снова откроется при нажатии",
    permanent: "Панель остается видимой независимо от размера экрана",
    right: "Помещает панель навигации справа",
    stateless:
      "Удалите все автоматизированные функции состояния (изменение размера, мобильность, роут) и вручную управляйте состоянием панели",
    temporary:
      "Временная панель находиться над своим приложением и использует холст (наложение), чтобы затемнить фон",
    touchless: "Отключить touch - функциональность мобильного телефона"
  }
}
