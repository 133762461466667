export default {
  app:
    "컴포넌트를 어플리케이션 레이아웃의 일부로 지정. 컨덴츠의 크기를 동적으로 맞추는데 사용됨.",
  auto: "Centers list on selected element",
  centered: "탭을 중앙 정렬",
  clearable:
    "Add input clear functionality, default icon is Material Icons **clear**",
  dontFillMaskBlanks: "Disables the automatic character display when typing",
  falseValue: "Sets value for falsy state",
  fullWidth: "100% 너비(width)를 가지도록 강제",
  fixedTabs: "`v-tabs-item` min-width 160px (72px mobile), max-width 264px",
  height: "컴포넌트의 높이를 설정",
  id: "컴포넌트의 DOM 아이디를 설정",
  mask:
    "Apply a custom character mask to the input. See mask table above for more information",
  max: "최대 너비(maximum width)를 설정",
  maxHeight: "컨탠츠의 최대 높이를 설정",
  min: "최소 너비(minimum width)를 설정",
  mobileBreakPoint: "Sets the designated mobile breakpoint",
  overlap:
    "컴포넌트가 슬롯에 추가된 컨덴츠와 중첩됨(Component will overlap the slotted content)",
  persistent: "대화창(dialog)의 외부를 클릭해도 대화창이 사라지지 않음",
  returnMaskedValue: "Returns the unmodified masked string",
  ripple: "Applies the `v-ripple` directive",
  shift: "Hide text of button when not active",
  singleLine: "Label does not move on focus/dirty",
  size: "Sets the height and width of the element",
  textColor: "Applies a specified color to the control text",
  tile: "Removes border radius",
  trueValue: "Sets value for truthy state",
  value: "표시여부를 조절 (Controls visibility)",
  width: "The width of the content"
}
