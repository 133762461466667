import { fetchData } from "../../utils/response"
import { getReasons } from "../../api/reason"
const state = {
  reason: []
}

const getters = {
  reason: state => {
    return state.reason
  } 
}

export const types = {
  SET_REASON: "SET_REASON"
}

const mutations = {
  [types.SET_REASON](state, data) {
    state.reason = data
  }
}

const actions = {
  getReasonsSettings({ commit }) {
    getReasons()
      .then(fetchData)
      .then(data => commit(types.SET_REASON, data))
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
