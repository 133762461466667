export default {
  header: "免费和付费主题",
  headerText:
    "Vuetify提供免费和高级预制主题，旨在让您在快速入门。您可通过vue-cli安装免费主题，也可以方便地下载源代码。",
  free: "免费",
  demo: "案例",
  comingSoon: "即将到来",
  sourceCode: "源代码",
  templates: [
    {
      screenshot: "/static/doc-images/starter/vuetify-premium.jpg",
      title: "Startup",
      tag: "付费",
      description:
        "使用这个精美的手工Material设计风格模版来展示您的企业或者个人介绍主页。",
      price: "25$",
      demoUrl: ""
    },
    {
      screenshot: "/static/doc-images/starter/vuetify-parallax-starter.png",
      title: "视差",
      description: "这种漂亮的单页视差风格是非常棒的应用主页。",
      sourceUrl: "vuetifyjs/parallax-starter",
      demoUrl: "/themes/parallax-starter"
    },
    {
      screenshot: "/static/doc-images/starter/vuetify-blog-starter.png",
      title: "博客",
      description: "一个用来构建清新界面的博客类应用程序的简单模版。",
      sourceUrl: "vuetifyjs/blog-starter",
      demoUrl: "/themes/blog-starter"
    }
  ]
}
