export default {
  header: "Form",
  headerText:
    "Когда дело доходит до валидации, Vuetify имеет множество интеграций и искусно работает. Хотите использовать сторонний плагин проверки? Из коробки вы можете использовать [Vee-validate](https://github.com/baianat/Vee-validate) and [vuelidate](https://github.com/monterail/vuelidate).",
  components: ["v-form"],
  examples: [
    {
      basicValidation: {
        header: "VForm - базовая валидация",
        desc:
          "Внутренний компонент `v-form` упрощает добавление валидации для формирования входных данных. Все входные компоненты имеют команду `rules`, которая принимает набор функций. Всякий раз, когда изменяется значение input, каждая функция в массиве получит новое значение. Если функция возвращает false или строку, проверка не выполнена."
      },
      validationWithSubmitAndClear: {
        header: "Проверка с помощью отправки и очистка",
        desc:
          "`v-form`компонент имеет две функции, к которым можно получить доступ, установив ref на компонент. `validate ()` будет проверять все input и возвращать, если они все действительны или нет. `reset ()` очистит ошибки проверки от всех input."
      },
      vuelidate: {
        header: "Vuelidate",
        desc: ""
      },
      veeValidate: {
        header: "Vee-validate",
        desc:
          'Vee-validate - это еще один плагин проверки, который позволяет вам проверять свои формы. Одно из предостережений заключается в том, что вы должны добавить **type="checkbox"** для правильной проверки `v-checkbox` при использовании **value** prop.'
      }
    }
  ],
  props: {
    lazyValidation:
      "Если включено, **value** всегда будет _true_, если нет видимых ошибок проверки. Вы все равно можете вызвать `validate ()` для ручного запуска проверки"
  },
  functions: {
    reset: "Сбрасывает подтверждение для всех input",
    validate: "Проверяет все input и возвращает результат"
  }
}
