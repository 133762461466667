// App Theme settings
/**
 * This setting is imported from src/data/json/AppColorScheme.json
 * Here we have choosen one of the theme among the list
 * Setting it's titled as Color Scheme
 * Ref: store/modules/swatch.js
 */
import schemes from "./schemes"

// Theme Base componet Config
/**
 * This is based config for theme UI.
 * Basically refers to different mod/visibility of 3 based navigation of an app.
 * Ref: store/modules/navigation.js
 */
import navigation from "./navigation"
import swatch from "./swatch"

export const configSchemes = schemes
export const configNavigation = navigation
export const configSwatch = swatch

/**
 * Url to call api
 *
 * @type {string}
 */
// export const apiUrl = "https://api.memes.redentu.com/api/"
// export const apiUrl = "https://api.misics.com/api/"
export const apiUrl = process.env.VUE_APP_API_URL

/**
 Need to change when host changed
 */
// export const BaseUrl = "https://memes.redentu.com/"
// export const BaseUrl = "https://misics.com/"
export const BaseUrl = process.env.VUE_APP_BASE_URL

// export const SiteUrl = "https://admin.memes.redentu.com/"
// export const SiteUrl = "https://admin.misics.com/"
export const SiteUrl = process.env.VUE_APP_SITE_URL

/**
 * Log level can be either debug|off
 *
 * @type {string}
 */
export const logLevel = "debug"

/**
 * Application name
 *
 * @type {string}
 */
export const appName = "MiSiCs"
// export const appName = process.env.VUE_APP_APP_NAME

/**
 * Default configs for application
 */
export const defaults = {
  authorizationFallbackRoute: "login"
}

export default {
  schemes,
  navigation,
  swatch
}
