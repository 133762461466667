import Contributing from "./Contributing"
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions"
import QuickStart from "./QuickStart"
import Roadmap from "./Roadmap"
import SponsorsAndBackers from "./SponsorsAndBackers"
import WhyVuetify from "./WhyVuetify"

export default {
  Contributing,
  FrequentlyAskedQuestions,
  QuickStart,
  Roadmap,
  SponsorsAndBackers,
  WhyVuetify
}
