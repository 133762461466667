export default {
  header: "Директива прокрутки",
  headerText:
    "Директива `v-scroll` позволяет вам предоставлять обратные вызовы при прокрутке окна или определенного элемента.",
  components: ["v-scroll"],
  examples: [
    {
      default: {
        header: "По умолчанию",
        desc:
          "По умолчанию используется привязка к окну. Если дополнительных параметров конфигурации не требуется, вы можете просто передать свою функцию обратного вызова.",
        uninverted: true
      },
      options: {
        header: "Прокрутка с параметрами",
        desc:
          "Для более точной настройки вы можете назначить цель для привязки слушателя событий прокрутки.",
        uninverted: true
      }
    }
  ],
  options: {
    "arg:target":
      '`v-scroll:#target="callback"` наблюдает за изменениями прокрутки. По умолчанию это window, но может быть изменено на любой действительный селектор id.',
    value: '`v-scroll="callback"` функция вызова в прокрутке'
  }
}
