// Pages
import Vuetify from "./vuetify"
import GettingStarted from "./getting-started"
import Guides from "./guides"
import Layout from "./layout"
import Style from "./style"
import Motion from "./motion"

// Generic
import Generic from "./generic"

// Components
import Components from "./components"

// Directives
import Directives from "./directives"

// Mixins
import Mixins from "./mixins"

import meta from "./meta"

export default {
  Components,
  Directives,
  Generic,
  GettingStarted,
  Guides,
  Layout,
  Mixins,
  Motion,
  Style,
  Vuetify,
  meta
}
