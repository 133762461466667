export default {
  header: "Pagination",
  headerText:
    "Компонент `v-pagination` используется для разделения длинных наборов данных, чтобы пользователь мог потреблять информацию. В зависимости от предоставленной длины компонент pagination будет автоматически масштабироваться. Чтобы сохранить текущую страницу, просто поставьте атрибут `v-model`.",
  components: ["v-pagination"],
  examples: [
    {
      short: {
        header: "Короткий",
        desc:
          "Pagination отображает все страницы, если родительский контейнер достаточно велик."
      },
      long: {
        header: "Длинный",
        desc:
          "Когда количество кнопок страницы превышает родительский контейнер, компонент усекает список."
      },
      limit: {
        header: "Лимит",
        desc:
          "Вы также можете вручную установить максимальное количество видимых кнопок страницы с помощью `total-visible` prop."
      },
      round: {
        header: "Круглые",
        desc:
          "Альтернативный стиль для разбивки на страницы - это круглые страницы."
      },
      icons: {
        header: "Иконки",
        desc:
          "Иконки предыдущей и следующей страницы можно настроить с помощью `prev-icon` и `next-icon` props."
      },
      disabled: {
        header: "Disabled",
        desc: "Элементы разбиения на страницы можно вручную отключить."
      }
    }
  ],
  props: {
    circle: "Элементы разбиения на фигуры в виде кругов",
    disabled: "Отключенный компонент",
    length: "Длина paginator",
    nextIcon:
      'Укажите иконку, которая будет использоваться для иконки "следующее"',
    prevIcon:
      'Укажите иконку, которая будет использоваться для иконки "предыдущее"',
    totalVisible:
      "Укажите максимальное количество отображаемых номеров страниц",
    value: "Текущая выбранная страница"
  }
}
