export default {
  header: "Выравнивание текста",
  headerText:
    "Макеты могут меняться при изменении разрешения экрана. Классы выравнивания помогают размещать текст на основе размера _viewport_ (область просмотра)",
  toc: [
    {
      text: "Введение",
      href: "introduction"
    },
    {
      text: "Примеры",
      href: "examples"
    }
  ]
}
