export default {
  props: {
    absolute: "요소를 절대 위치 시킴",
    bottom: "컴포넌트를 아랫쪽(bottom)으로 정렬",
    fixed: "요소의 위치를 고정",
    left: "요소를 왼쪽으로 정렬",
    right: "요소를 오른쪽으로 정렬",
    top: "요소를 위쪽(top)으로 정렬"
  }
}
