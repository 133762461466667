export default {
  props: {
    activator:
      "Designate a custom activator when the activator slot is not used. String can be any valid querySelector and Object can be any valid Node",
    allowOverflow: "Removes overflow re-positioning for the content",
    maxWidth: "Sets the maximum width for the content",
    minWidth: "Sets the minimum width for the content",
    nudgeBottom: "Nudge the content to the bottom",
    nudgeLeft: "Nudge the content to the left",
    nudgeRight: "Nudge the content to the right",
    nudgeTop: "Nudge the content to the top",
    nudgeWidth: "Nudge the content width",
    offsetOverflow:
      "Causes the component to flip to the opposite side when repositioned due to overflow",
    positionX: "Used to position the content when not using an activator slot",
    positionY: "Used to position the content when not using an activator slot",
    zIndex: "The z-index used for the component"
  }
}
