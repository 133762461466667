import { fetchData } from "../../utils/response"
import { getLanguages, getSupportedLanguages } from "../../api/languages"
const state = {
  languages: null,
  supportedLanguages: []
}

const getters = {
  languages: state => {
    return state.languages 
  },
  supportedLanguages: state => {
    return state.supportedLanguages
  }
}

export const types = {
  SET_LANGUAGES: "SET_LANGUAGES",
  SET_SUPPORTED_LANGUAGES: "SET_SUPPORTED_LANGUAGES"
}

const mutations = {
  [types.SET_LANGUAGES](state, data) {
    state.languages = data
  },
  SET_SUPPORTED_LANGUAGES (state, data) {
    state.supportedLanguages = data
  }
}

const actions = {
  getAllLanguages({ commit }) {
    getLanguages()
      .then(fetchData)
      .then(data => commit(types.SET_LANGUAGES, data))
  },

  async getSupportedLanguages({ commit }) {
    await getSupportedLanguages()
        .then(fetchData)
        .then(data => commit('SET_SUPPORTED_LANGUAGES', data))
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
