export default {
  header: "Elevation",
  headerText:
    "The elevation helpers allow you to control relative depth, or distance, between two surfaces along the **z-axis**. There is a total of 25 elevation levels. You can set an element's elevation by using the class `elevation-{n}`, where `n` is a integer between 0-24 corresponding to the desired elevation.",
  toc: [
    {
      text: "Introduction",
      href: "introduction"
    },
    {
      text: "Playground",
      href: "playground"
    }
  ],
  playgroundHeader: "Playground"
}
