import {convertParentTag, getTagById} from '@/api/tags'

const state = {
    tag: {}
}

const getters = {
    tagData: state => {
        return state.tag
    }
}

const mutations = {
    SET_TAG_DATA (state, payload) {
        state.tag = payload
    }
}

const actions = {
    async getTag ({ commit }, id) {
        await getTagById(id)
            .then(response => response.data)
            .then(data => commit('SET_TAG_DATA', data))
    },
    async convertParentTag (tag) {
        await convertParentTag(tag)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
