export default {
  app:
    "Designates the component as part of the application layout. Used for dynamically adjusting content sizing",
  auto: "Centers list on selected element",
  centered: "Centers the tabs",
  clearable:
    "Add input clear functionality, default icon is Material Icons **clear**",
  dontFillMaskBlanks: "Disables the automatic character display when typing",
  falseValue: "Sets value for falsy state",
  fullWidth: "Forces 100% width",
  fixedTabs: "`v-tabs-item` min-width 160px (72px mobile), max-width 264px",
  height: "Sets the component height",
  id: "Sets the DOM id on the component",
  mask:
    "Apply a custom character mask to the input. See mask table below for more information",
  max: "Sets maximum width",
  maxHeight: "Sets the maximum height for the content",
  min: "Sets minimum width",
  mobileBreakPoint: "Sets the designated mobile breakpoint",
  overlap: "Component will overlap the slotted content",
  persistent: "Clicking outside will not dismiss the dialog",
  returnMaskedValue: "Returns the unmodified masked string",
  ripple: "Applies the `v-ripple` directive",
  shift: "Hide text of button when not active",
  singleLine: "Label does not move on focus/dirty",
  size: "Sets the height and width of the element",
  textColor: "Applies a specified color to the control text",
  tile: "Removes border radius",
  trueValue: "Sets value for truthy state",
  value: "Controls visibility",
  width: "The width of the content"
}
