// TODO: import Contributing from './Contributing'
// TODO: import FrequentlyAskedQuestions from './FrequentlyAskedQuestions'
import QuickStart from "./QuickStart"
// TODO: import Roadmap from './Roadmap'
// TODO: import SponsorsAndBackers from './SponsorsAndBackers'
import WhyVuetify from "./WhyVuetify"

export default {
  // TODO: Contributing,
  // TODO: FrequentlyAskedQuestions,
  QuickStart,
  // TODO: Roadmap,
  // TODO: SponsorsAndBackers,
  WhyVuetify
}
