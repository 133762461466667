export default {
  header: "Текстовое поле",
  headerText:
    "Компоненты текстовых полей используются для сбора предоставленной пользователем информации.",
  components: ["v-text-field"],
  supplemental: ["MaskTable"],
  examples: [
    {
      label: {
        header: "С меткой",
        desc:
          "Текстовые поля располагаются в двух вариантах тем, темных и светлых."
      },
      singleLine: {
        header: "Однострочная светлая тема",
        desc:
          "Однострочные текстовые поля не всплывают на метки с фокусом или с данными."
      },
      icon: {
        header: "С иконкой",
        desc: "Иконки могут быть указаны как присоединенные или добавленные."
      },
      multiLine: {
        header: "Многострочный",
        desc:
          "Многострочное текстовое поле Multi-LineA полезно для больших объемов текста."
      },
      characterCounter: {
        header: "Счетчик символов",
        desc:
          "Используйте счетчик, чтобы проинформировать пользователя о значении лимита. Счетчик не выполняет никакой проверки самостоятельно. Вам необходимо соединить его либо с внутренней системой проверки, либо с библиотекой сторонних разработчиков."
      },
      password: {
        header: "Ввод пароля",
        desc:
          "Ввод пароля может использоваться с добавленным значком и обратным вызовом для контроля видимости."
      },
      validation: {
        header: "Валидация",
        desc:
          "Vuetify включает в себя простую проверку с помощью `rules`. Подпрограмма принимает массив обратных вызовов. При проверке правил текущее значение v-model будет передано на обратный вызов. Этот обратный вызов должен возвращать либо `true`, либо `String`, сообщение об ошибке."
      },
      fullWidthWithCharacterCounter: {
        header: "Текстовое поле полной ширины с символьным счетчиком",
        desc:
          "Полные текстовые поля позволяют создавать безграничные вводы. В этом примере мы используем `v-divider` для разделения полей."
      },
      requiredFields: {
        header: "Обязательные поля",
        desc:
          "В этом примере требуются два текстовых поля. Мы используем **required** для применения звездочки к метке и правила для проверки полей."
      },
      hint: {
        header: "Текст подсказки",
        desc:
          "Свойство **hint** в текстовых полях добавляет предоставленную строку под текстовым полем. Использование **persistent-hint** сохраняет подсказку, когда текстовое поле не фокусируется."
      },
      prefixesAndSuffixes: {
        header: "Префиксы и суффиксы",
        desc:
          "Свойство **prefix** и **suffix** позволяет добавлять и добавлять встроенный немодифицируемый текст рядом с текстовым полем"
      },
      customValidation: {
        header: "Пользовательская проверка",
        desc:
          "Хотя встроенный в `v-form` или сторонний плагин, такой как [vuelidate](https://github.com/monterail/vuelidate) или [vee-validation](https://github.com/logaretm/vee-validate) может помочь упростить процесс проверки, вы можете просто контролировать его самостоятельно."
      },
      textarea: {
        header: "Textarea",
        desc: "Текстовые поля textarea имеют альтернативный стиль.",
        uninverted: true
      },
      box: {
        header: "Стиль бокса",
        desc:
          "Текстовые поля могут использоваться с альтернативным дизайном бокса. Добавленная и присоедененная иконка перед именем реквизита **не** поддерживается в этом режиме."
      },
      solo: {
        header: "Solo style",
        desc: "Text-fields can be used with an alternative solo design."
      },
      customColors: {
        header: "Пользовательские цвета",
        desc:
          "Вы можете при необходимости изменить текстовое поле на любой цвет в палитре Design Material. Ниже приведен пример реализации пользовательской формы с проверкой."
      },
      masks: {
        header: "Маски",
        desc:
          "Текстовые поля могут проверять маски символов. Используя либо готовые, либо настраиваемые правила, вы можете форматировать и проверять конкретные наборы символов."
      },
      progressBar: {
        header: "Progress bar",
        desc:
          "Вместо нижней строки можно отобразить индикатор выполнения. Вы можете использовать неопределенный прогресс по умолчанию, имеющий тот же цвет, что и текстовое поле, или назначить пользовательский, используя слот `progress`"
      }
    }
  ],
  props: {
    autoGrow:
      "Автоматически увеличивает вход. Этот вариант требует использования **v-model**",
    autofocus: "Включить автофокусировку",
    box: "Применяет стиль ввода альтернативного ввода",
    counter:
      "Создает счетчик для входной длины. Если номер не указан, по умолчанию он равен 25. Не применяется какая-либо проверка.",
    fullWidth: "Сбрасывает тип ввода как полную ширину",
    multiLine: "Входит в текстовое поле",
    prefix: "Отображает префиксный текст",
    rows: "Количество строк в textarea",
    suffix: "Отображает суффикс-текста",
    textarea: "Текстовое поле textarea с альтернативным стилем",
    toggleKeys:
      "Массив кодов клавиш, которые будут переключаться на вход (если он поддерживает переключение)",
    type: "Устанавливает тип ввода"
  },
  events: {
    change: "Mixins.Input.events.change",
    "update:error": "Mixins.Input.events.updateerror"
  }
}
