export default {
  Any: "Любой",
  Array: "Массив",
  Boolean: "Boolean",
  Function: "Функция",
  Number: "Число",
  Object: "Object",
  String: "Строка",
  undefined: "undefined"
}
