export default {
  close: "Close",
  init: "Init command copied",
  quickstart: {
    title: "Одноминутный быстрый старт",
    desc:
      "Полезно для быстрого просмотра возможностей инфраструктуры Vuetify. Поскольку Vuetify может быть привязан во время выполнения. Даже в средах без процесса сборки вы можете создавать красивые шаблоны, связанные с шаблонами."
  },
  simple: {
    title: "Простой HTML",
    desc:
      "Этот шаблон предназначен для пользователей, которые хотят попробовать Vue.js и Vuetify.js самым простым способом. Он содержит базовый index.html без дополнительной функциональности. Это полезно для разработчиков, которые хотят легко просмотреть функции фреймворка."
  },
  webpack: {
    title: "Webpack",
    desc:
      "Этот шаблон предназначен для пользователей, которые ищут из коробки линтинг и модульное тестирование."
  },
  webpackSimple: {
    title: "Webpack Simple",
    desc:
      "Этот шаблон предназначен для пользователей, уже знакомых с Vue / Webpack. Он содержит очень простую настройку webpack и предназначен для разработчиков, создающих прототипы или базовые приложения."
  },
  webpackSSR: {
    title: "Webpack SSR",
    desc:
      'Этот шаблон предназначен для опытных пользователей, которые хотят использовать новый Vue SSR (рендеринг на стороне сервера). Исходя из структуры в Vue.js 2 <a class="white--text" href="https://github.com/vuejs/vue-hackernews-2.0" target="_blank" rel="noopener"></a> репозиторий. Шаблон SSR Vuetify.js обеспечивает функциональность следующего поколения для расширенных приложений Vue.'
  },
  nuxt: {
    title: "NUXT",
    desc:
      "Используя силу NUXT, переведите свой опыт разработки на новый уровень с помощью загрузочной версии, готовой к работе с Vuetify из коробки."
  },
  pwa: {
    title: "PWA",
    desc:
      "В вашем распоряжении предварительно настроенный шаблон PWA (прогрессивное веб-приложение). Загрузитесь с service workers, манифестами приложений и 90 + / 100 Lighthouse score ."
  },
  electron: {
    title: "Electron",
    desc:
      "Vuetify's официальный Electron шаблон для создания настольных приложений."
  },
  aLaCarte: {
    title: "A La Carte",
    desc:
      "В этом шаблоне вы можете увидеть пример выбора только тех компонентов, которые вы хотите использовать. Это полезно для уменьшения размера пакета с неиспользуемыми компонентами."
  },
  cordova: {
    title: "Cordova",
    desc:
      "Этот шаблон использует Apache Cordova, чтобы помочь вам легко конвертировать ваше веб-приложение в родное приложение Android или iOS.*"
  }
}
