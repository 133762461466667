export default {
  header: `支持Vuetify的开发`,
  headerText:
    'Vuetify是一个在MIT授权下的开源项目。它现在允许并且永远允许免费使用。 然而, 维护核心框架和所有补充包所花费的工作量是相当繁重的。为了达成Material Design规范完成后能继续开发额外功能的目标，您可以通过Patreon<a href="https://www.patreon.com/vuetify" target="_blank" rel="noreferrer">赞助Vuetify</a>.',
  backersHeader: "Patreon上的赞助商们:",
  backersSubHeader: "钻石 ($500/月)",
  backersSubHeader2: "钯 ($250/月)",
  backersSubHeader3: "白金 ($100/月)",
  backersSubHeader4: "黄金 ($50/月)",
  affiliatesHeader: "接入商:",
  sponsorsHeader: "服务赞助商：",
  questionHeader: "还有其他问题吗？",
  questionButton: "联系我们"
}
