export default {
  header: "Text alignment",
  headerText:
    "Layouts can change when screen resolutions change. The alignment classes help you position your text based on viewport size.",
  toc: [
    {
      text: "Introduction",
      href: "introduction"
    },
    {
      text: "Examples",
      href: "examples"
    }
  ]
}
