import api from "./index"

/**
 * Get all tags
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getAllTagsRequest = (params, includes = []) => {
  return api
    .request("admin/tags")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

export const getTagById = (id) => {
    return api
        .request(`admin/tags/${id}`)
        .get()
}

export const convertParentTag = (tag) => {
    return api.request(`admin/tags/${tag.id}/convert`).post()
}

/**
 * Edit tag
 *
 * @param id
 * @param data
 * @param includes
 * @return {*}
 */
export const editTagRequest = (id, data, includes = []) => {
  return api
    .request(`admin/tag/${id}/edit`)
    .withHeaders({
      "Content-type": "multipart/form-data"
    })
    .withBody(data, true)
    .withIncludes(includes)
    .post()
}

/**
 * Add tag
 *
 * @param data
 * @param includes
 * @return {*}
 */
export const addTagRequest = (data, includes = []) => {
  return api
    .request(`admin/tags`)
    .withHeaders({
      "Content-type": "multipart/form-data"
    })
    .withBody(data, true)
    .withIncludes(includes)
    .post()
}

/**
 * Delete tag
 *
 * @param id
 * @return {*}
 */
export const deleteTagRequest = id => {
  return api.request(`admin/tag/${id}/delete`).delete()
}

/**
 * Delete alias tag
 *
 * @return {*}
 * @param params
 * @param includes
 */
export const deleteTagAliasRequest = (params, includes = []) => {
  return api
    .request(`admin/tag/alias/delete`)
    .withParams(params)
    .withIncludes(includes)
    .patch()
}
