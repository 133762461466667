export default {
  header: "Поддержка Касания",
  headerText:
    "Директива `v-touch`позволяет вам фиксировать жесты и использовать направленные обратные вызовы.",
  components: ["v-touch"],
  examples: [
    {
      default: {
        header: "По умолчанию",
        desc:
          "На мобильном устройстве попробуйте сделать жест в разных направлениях.",
        uninverted: true
      }
    }
  ],
  options: {
    "{ move, start, end }":
      "Назначить обратный вызов, когда событие касания начинается, заканчивается и во время его выполнения",
    "{ up, down, left, right }":
      "Назначьте обратный вызов на основе направления движения. В настоящее время не рекомендуется обращать вызовы по оси `x` и `y` "
  }
}
