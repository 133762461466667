import { log } from "../utils/helpers"
/**/
const services = {
  /**
   * Check access over roles and permissions
   *
   * @param to
   * @return {boolean}
   */
  checkAccess(to) {
    if (!to.meta.access) {
      return true
    }

    const roles = to.meta.access.roles || []
    const permissions = to.meta.access.permissions || []

    const hasAnyPermissionsAccess = this.$accessHasPermissions
      ? this.$accessHasPermissions(...permissions)
      : true
    const hasAnyRolesAccess = this.$accessHasRoles
      ? this.$accessHasRoles(...roles)
      : true

    return hasAnyPermissionsAccess || hasAnyRolesAccess
  },

  /**
   * Handle not found routes
   *
   * @param to
   * @param from
   * @param params
   * @return {boolean}
   */
  handleNotFoundRoutes(to, from, params) {
    const { resolved } = this.$router.resolve(to.fullPath)

    const redirect = resolved.name === "not_found" && to.name !== "not_found"

    if (redirect) {
      params.name = resolved.name

      return false
    }

    return true
  },

  /**
   * Log moving through routes
   *
   * @param to
   * @param from
   * @return {boolean}
   */
  logger(to, from) {
    log("[ROUTER]", from.name + " -> " + to.name)

    return true
  },
  check(to) {
    if (!to.meta.roles) {
      return true
    }

    const roles = to.meta.roles || []

    const hasAnyRoles = this.$checkRoles
        ? this.$checkRoles(...roles)
        : true

    return hasAnyRoles
  },
}

// const middlewares = [
//   services.check,
//   services.checkAccess,
//   services.handleNotFoundRoutes,
//   services.logger
// ]
const middlewares = [
  services.check,
  services.logger
]
/**
 * Handle checking user roles before enters route
 */
export const initializeRouterAuthMiddleware = app => {
  const { $router: router } = app

  let accessDeniedParams = { name: "home" }

  const handle = (to, from, next) => {


    let role
    if (localStorage.getItem('user')
        && JSON.parse(localStorage.getItem('user')).value
        && JSON.parse(localStorage.getItem('user')).value.role) {
       role = JSON.parse(localStorage.getItem('user')).value.role
    } else {
      role = null
    }

    if (role === 'Editor' ) {
      accessDeniedParams = { name: "parser_need_approve_posts", params: { name: 'telegram' } }
    }
    const hasAccess = middlewares
      .map(x => x.call(app, to, from, accessDeniedParams))
      .every(x => x)

    return hasAccess ? next() : next(accessDeniedParams)
  }

  router.beforeResolve(handle)
}
