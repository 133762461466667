export default {
  props: {
    absolute: "Position the element absolutely",
    bottom: "Align the component towards the bottom",
    fixed: "Position the element fixed",
    left: "Align the component towards the left",
    right: "Align the component towards the right",
    top: "Align the content towards the top"
  }
}
