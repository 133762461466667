export default {
  indexHeader: "Welcome to our new Store!",
  indexSubheader: "Supercharge your Development",
  cartHeader: "Your Cart",
  cartSubheader: "You're almost done!",
  cartEmptySubheader: "Your cart is currently empty",
  thankYouHeader: "Thank you!",
  thankYouSubheader:
    "Your purchase helps support the continued maintenance and development of Vuetify",
  helpSupport:
    "Help support Vuetify's ongoing development and get yourself and your team some swag!",
  checkout: "Proceeed to checkout",
  addToCart: "Add to Cart",
  details: "Details",
  description: "Description",
  back: "Back",
  backToStore: "Continue Shopping",
  thankYouBack:
    "Having issues?<br>Come to the [community](https://community.vuetifyjs.com)",
  onSale: "On Sale",
  cartHeaders: [
    {
      text: "Product",
      value: false,
      sortable: false
    },
    {
      text: "Name",
      value: false,
      sortable: false
    },
    {
      text: "Price",
      align: "right",
      value: false,
      sortable: false
    },
    {
      text: "Quantity",
      align: "right",
      value: false,
      sortable: false,
      width: "50px"
    }
  ]
}
