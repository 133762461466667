<template>
  <v-img :src="backgroundImg" class="page-vimg">
    <v-container fill-height pa-0>
      <v-layout align-center>
        <v-flex xs12>
          <validation-observer ref="validationObserver">
            <v-card
              slot-scope="{ invalid, validated }"
              class="text-xs-center margin-auto border-radius6 elevation-10 auth-box"
            >
              <v-layout align-center justify-center row fill-height wrap>
                <v-flex xs12 class="text-xs-center mt-3">
                  <div class="logo-wrap pa-4 mt-2 mb-4">
                    <img
                      src="@/assets/logo.svg"
                      class="text-xs-center"
                      :alt="siteName"
                      :title="siteName"
                    >
                  </div>
                  <div class="headline">
                    Sign in to your account
                  </div>
                  <v-form ref="form" @submit.prevent="submit()">
                    <v-layout wrap row pa-4>
                      <v-flex xs12 pa-0>
                        <validation-text-field
                          v-model="field"
                          color="primary"
                          :success="false"
                          name="field"
                          rules="required"
                          label="Email or User Name"
                          required
                          :error-messages="
                            errors.collect('username') ||
                              errors.collect('email')
                          "
                          @input="emailInput"
                        />

                        <validation-text-field
                          v-model="password"
                          color="primary"
                          :success="false"
                          name="password"
                          rules="required|min:6|max:50"
                          label="Password"
                          type="password"
                          required
                          :error-messages="errors.collect('password')"
                          @input="passwordInput"
                        />
                      </v-flex>
                      <v-alert
                        :value="
                          errors.has('password') ||
                            errors.has('email') ||
                            errors.has('username') ||
                            errors.has('common')
                        "
                        color="error"
                        icon="warning"
                        outline
                      >
                        <div>{{ errors.first("common") }}</div>
                        <div>{{ errors.first("email") }}</div>
                        <div>{{ errors.first("username") }}</div>
                        <div>{{ errors.first("password") }}</div>
                      </v-alert>

                      <v-flex xs12>
                        <v-layout row wrap text-xs-center>
                          <!-- Login form submit -->
                          <v-flex xs12 class="no-mrpd">
                            <v-btn
                              color="act"
                              :loading="isLoading"
                              :disabled="invalid || !validated"
                              type="submit"
                              block
                              class="white--text"
                            >
                              Login
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-flex>
              </v-layout>
            </v-card>
          </validation-observer>
        </v-flex>
      </v-layout>
    </v-container>
    <v-snackbar v-model="snackbar" absolute top right color="act">
      <span>Sign in successful!</span>
      <v-icon dark>
        check_circle
      </v-icon>
    </v-snackbar>
    <v-snackbar
      v-model="accessSnackbar"
      absolute
      top
      right
      color="red"
      :timeout="3000"
    >
      <span>You do not have access for this page</span>
      <v-icon dark>
        block
      </v-icon>
    </v-snackbar>
  </v-img>
</template>

<script>
import { loginRequest, getUserInfo } from "../../../api/auth"
import { setAccessToken } from "../../../utils/axios"
import { mapMutations, mapActions } from "vuex"
import { types } from "../../../store/modules/user"
// import { types as salonTypes } from "../../../store/modules/salon";
// import { LaravelEcho } from "../../../mixins/LaravelEcho";

// const defaultForm = {
//   email: "",
//   password: ""
// };

const accessRoles = ["admin", "employee"]
import { appName } from "../../../config"

export default {
  // mixins: [LaravelEcho],
  data() {
    return {
      // form: Object.assign({}, defaultForm),
      form: {},
      field: "",
      email: "",
      username: "",
      password: "",
      snackbar: false,
      isLoading: false,
      accessSnackbar: false,
      backgroundImg: "/static/doc-images/HexesisMaterial01.png",
      siteName: appName,
    }
  },
  methods: {
    ...mapMutations({
      setUser: types.SET_AUTH_USER,
      setUserInfo: types.SET_USER_INFO
      // setHandleState: salonTypes.SET_HANDLE_STATE
    }),
    ...mapActions([
      // "loadSalons"
    ]),
    emailInput() {
      this.errors.clear()
    },
    passwordInput() {
      this.errors.clear()
    },
    async submit() {
      this.accessSnackbar = false
      this.errors.clear()
      const valid = await this.$refs.validationObserver.validate()

      if (!valid) {
        return
      }

      this.form = {}

      this.field = this.field.trim()

      this.validateEmail(this.field)
        ? (this.form.email = this.field)
        : (this.form.username = this.field)

      this.form.password = this.password

      this.isLoading = true

      const response = await loginRequest(this.form).catch(
        this.populateErrorBags
      )

      this.isLoading = false

      if (response && response.status !== 200) {
        return
      }

      if (this.checkAccess(response.data.data)) {
        this.accessSnackbar = true
        return
      }
      this.handleResponse(response)
      await this.fetchUserInfo()
      this.snackbar = true
      this.resetForm()

      this.$store.state.user.userInfo.role === 'Editor'
          ? await this.$router.push({ name: "parser_need_approve_posts", params: { name: 'telegram' } })
          : await this.$router.push({ name: "users" })
    },
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    resetForm() {
      this.form = {}
      this.$refs.form.reset()
      this.$refs.validationObserver.reset()
    },

    /**
     * Handle login response
     * @param response
     */
    handleResponse(response) {
      const accessToken = response.data.data.token
      const user = response.data.data
      setAccessToken("Bearer", accessToken)

      this.setUser(user)

      this.$ls.set("user", user)
      this.$ls.set("access_token", accessToken)

      // this.loadSalons();

      // this.setHandleState(true);
    },

    /**
     * @param user
     */
    checkAccess(user) {
      const roles = user.roles || []

      return roles.find(x => accessRoles.includes(x.name))
    },
    async fetchUserInfo() {
      const responseUserInfo = await getUserInfo().catch(
        this.populateErrorBags
      )
      this.setUserInfo(responseUserInfo.data.data)
    }
  }
}
</script>
<style>
.auth-box .v-alert.v-alert--outline {
  width: 100%;
  min-width: 270px;
  max-width: 100%;
  padding: 16px 8px;
  argin: 12px 0;
}
.auth-box .v-alert.v-alert--outline .v-alert__icon {
  margin-right: 4px;
}
.auth-box.theme--dark.v-card {
  background-color: #2d2d2f;
}
.auth-box .v-messages__message,
.auth-box .error--text {
  font-weight: 600;
}
.logo-wrap svg {
  width: 100%;
}
</style>
