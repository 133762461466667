export default {
  announcementBanner:
    "Aww yisss, this is an exciting announcement! Or maybe its an advertisement!",
  heading1: "Vuetify",
  heading1cont: "Material Component Framework",
  getStarted: "はじめる",
  getHelp: "助けて",
  features: [
    {
      img: "/static/doc-images/feature1.svg",
      title: "プロジェクトテンプレート",
      text:
        "8つのvue-cli用のテンプレートを用意しています。シンプルなHTMLからSSRを使用した本格的なものまで、数分で作成できます。"
    },
    {
      img: "/static/doc-images/feature2.svg",
      title: "セマンティックコンポーネント",
      text:
        "80種類以上のコンポーネントが用意されており、どんなアプリケーションにも対応することができます。"
    },
    {
      img: "/static/doc-images/feature3.svg",
      title: "活発なコミュニティ",
      text:
        "つまづいてしまったらすぐに助けが必要です。Discord上でコミュニティによるチャットサポートを用意しています。"
    }
  ],
  checkFeaturesTitle: "最高のUIを構築するために",
  checkFeaturesTitleCtd: "必要なすべてがそろっています",
  checkFeatures: [
    "コミュニティによるチャットサポート",
    "動的なテーマリング",
    "SSR と PWA のサポート",
    "vue-cli 向けの8つのテンプレート"
  ],
  checkFeaturesCtd: [
    "80種類以上のコンポーネント",
    "コンポーネント個別のインポート",
    "トランジションコンポーネント",
    "圧倒的な速さ"
  ],
  letterFromAuthor: [
    "みなさんこんにちは。",
    "私は、UIフレームワークの選定は難しい作業であることを理解しています。数多くの選択肢があり、それぞれが自身をより良いものであるとアピールしています。そのため、成功のための指標が何であるのか？ということを自身で決める必要があります。",
    "これは約１年ほど前の私でした。選択肢に挙がったUIツールキットは沢山ありましたが、それらのどれ1つとして私の必要とする機能を含んでいなかったため、Vuetifyの開発を開始しました。",
    "あなたが次なるUIフレームワークとしてVuetifyを使用する検討することについては私はとても謙虚でいます。構想から生産までのあらゆるアイディアを取り入れるための沢山の気配りが完璧な構成を形作りました。あなたがVuetifyのコミュニティに参加し、素晴らしいUIを作成することを楽しみにしています！"
  ],
  authorOfVuetify: "Vuetify作者",
  support: {
    hasVuetifyHelped: "Vuetifyは素敵なアプリを作成する力になりましたか？",
    showYourSupport: "よろしければVuetifyを",
    becomingAPatron: "Patronで支援してください"
  },
  becomeSponsor: "スポンサーになる",
  proudlySponsoredBy: "Patreon Sponsors",
  callout: "Vueとマテリアルデザインの力で、あなたの開発を加速しましょう"
}
