import {mapGetters} from "vuex"
import {isUndefinedOrNull} from "../utils/helpers"

/**
 * Helps check user permissions or roles
 */
export default { 
  methods: {
    /**
     * Check whether user has permission
     *
     * @param permissions
     * @return {boolean}
     */
    $accessHasPermissions(...permissions) {
      const permission = this.permissions.find(x =>
        permissions.includes(x.name)
      )

      return !isUndefinedOrNull(permission)
    },

    /**
     * Check whether user has permission
     *
     * @param roles
     * @return {boolean}
     */
    $accessHasRoles(...roles) {
      const userRoles = this.user ? this.user.roles : []

      const role = userRoles.find(x => roles.includes(x.name))

      return !isUndefinedOrNull(role)
    },

    $checkRoles(...roles) {
      const userRoles = this.user ? this.user.role : []

      return roles.includes(userRoles)
    }
  },
  computed: mapGetters(["permissions", "user"])
}
