import Alignment from "./Alignment"
import Display from "./Display"
import Elevation from "./Elevation"
import Grid from "./Grid"
import GridLists from "./GridLists"
import PreDefined from "./PreDefined"
import PreMadeThemes from "./PreMadeThemes"
import Sandbox from "./Sandbox"
import Spacing from "./Spacing"

export default {
  Alignment,
  Display,
  Elevation,
  Grid,
  GridLists,
  PreDefined,
  PreMadeThemes,
  Sandbox,
  Spacing
}
