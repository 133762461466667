export const ValidateableComponent = {
  props: {
    /**
     * The id of validator
     */
    vid: String,

    /**
     * Validation rules
     */
    rules: {
      type: [Object, String], 
      default: ""
    }
  }
}
