<template>
  <v-snackbar v-model="isVisible" v-bind="props" top>
    {{ content }}
  </v-snackbar>
</template>

<script>
export default {
  name: "Notification",
  data() {
    return {
      isVisible: false, 
      content: null,
      props: {}
    }
  },
  created() {
    this.$bus.on("notifications.open", this.onNotificationOpenListener)
    this.$bus.on("notifications.close", this.onNotificationCloseListener)
  },
  beforeDestroy() {
    this.$bus.off("notifications.open", this.onNotificationOpenListener)
    this.$bus.off("notifications.close", this.onNotificationCloseListener)
  },
  methods: {
    /**
     * Handle notification open
     */
    onNotificationOpenListener({ content, props }) {
      this.isVisible = true
      this.content = content
      this.props = props
    },

    /**
     * Handle notification close
     */
    onNotificationCloseListener() {
      this.isVisible = false
    }
  }
}
</script>

<style scoped></style>
