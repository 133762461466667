<template>
  <ValidationProvider
    :name="$attrs['data-vv-as'] || $attrs.name"
    :vid="vid"
    :rules="rules"
  >
    <v-textarea
      v-model="innerValue"
      slot-scope="{ errors, valid }"
      :error-messages="errors"
      :success="valid && showSuccess"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </ValidationProvider>
</template>

<script>
import { ValidateableComponent } from "../../mixins/ValidateableComponent"

export default {
  name: "ValidationTextArea",
  mixins: [ValidateableComponent],
  props: {
    // must be included in props
    value: {
      type: null
    },

    /**
     * Whether to show success field
     */
    showSuccess: Boolean
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal)
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>

<style scoped></style>
