import Colors from "./Colors"
import Theme from "./Theme"
import Typography from "./Typography"
import Content from "./Content"

export default {
  Colors,
  Theme,
  Typography,
  Content
}
