import api from "./index"

/**
 * Get reason for Ban
 *
 * @return {*}
 */
export const getReasons = () => {
    return api.request(`admin/reasons`).get() 
}
