import { isObject, momentize } from "./helpers"

const populateBags = (bag, data) => {

  const errors = isObject(data.errors)
    ? Object.entries(data.errors)
    : Object.entries({ common: [data.message] })

  errors.map(([field, messages]) => {
    messages.map(message =>
      bag.add({ field: field.split(".")[0], msg: message })
    )
  }) 
}

/**
 * Populates vee-validate errors bags with server response
 *
 * @type {{methods: {populateErrorBags(*): *}}}
 */
export const populatesErrorBags = {
  methods: {
    populateErrorBags(e) {
      if (!e || !e.response) {
        return e
      }

      populateBags(this.errors, e.response.data)
      return e
    },

    /**
     * Populate error bags in scope
     *
     * @param context
     * @return {function(*): *}
     */
    scopePopulateErrorBags(context) {
      return e => {
        if (!e || !e.response) {
          return e
        }

        populateBags(context.errors, e.response.data)

        return e
      }
    }
  }
}

/**
 * Fetches data from response
 *
 * @param response
 * @return {*}
 */
export const fetchData = response => response.data.data

/**
 * Momentize response collection
 *
 * @param fields
 * @return {Function}
 */
export const toMoment = fields => collection =>
  collection.map(x => momentize(x, fields))

/**
 * Makes blank pagination
 *
 * @return {{total: number, count: number, per_page: number, current_page: number, total_pages: number}}
 */
export const blankPagination = () => ({
  total: 0,
  count: 0,
  per_page: 10,
  current_page: 1,
  total_pages: 1
})
