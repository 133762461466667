import api from "./index"

/**
 * Get all users
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getAllUsersRequest = (params, includes = []) => {
  return api 
    .request("admin/users")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Get all reported users
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getReportedUsersRequest = (params, includes = []) => {
  return api
    .request("admin/users/reported_users")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Get all banned users
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getBlockedUsersRequest = (params, includes = []) => {
  return api
    .request("admin/users/banned")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Get model users
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getModelUsersRequest = (params, includes = []) => {
  return api
    .request("admin/model-user-list")
    .withParams(params)
    .withIncludes(includes)
    .get()
}
