const state = {
    newItem: null,
    newModel: null,
}

const getters = {
    newItem: state => state.newItem,
    newModel: state => state.newModel,
}

export const types = {
    SET_NEW_ITEM: "SET_NEW_ITEM",
    SET_NEW_MODEL: "SET_NEW_MODEL"
}

const mutations = {
    [types.SET_NEW_ITEM](state, data) {
        state.newItem = data
    },
    [types.SET_NEW_MODEL](state, data) {
        state.newModel = data
    }
}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions
}