export default {
  header: "Slider",
  headerText:
    "Компонент `v-slider` является лучшей визуализацией ввода чисел. Он используется для обработки числовых пользовательских данных.",
  components: ["v-slider"],
  examples: [
    {
      continuous: {
        header: "Непрерывный",
        desc:
          "Непрерывные слайдеры должны использоваться, когда требуется невысокая точность."
      },
      discrete: {
        header: "Дискретный",
        desc:
          "Дискретные слайдеры предлагают визуализатор, который отображает точное текущее значение. С помощью свойства `step` вы можете запретить выбор значений за пределами шагов."
      },
      icons: {
        header: "Иконки",
        desc:
          "Вы можете добавлять иконки в слайдер с помощью свойств `append-icon` и `preend-icon`."
      },
      editableNumericValue: {
        header: "С редактируемым числовым значением",
        desc:
          "Слайдеры можно комбинировать с другими компонентами для лучшего отображения."
      },
      customColors: {
        header: "Пользовательские цвета",
        desc:
          "Вы можете использовать различные цвета используя свойства `color`, `track-color` и `thumb-color`."
      }
    }
  ],
  props: {
    step: "Если значение больше 0, задает интервал шагов для тиков",
    thumbColor: "Установка визуализации и цвета метки визуализации",
    thumbLabel: "Показать визуализатор",
    ticks: "",
    trackColor: "Устанавливает цвет дорожки"
  }
}
