export default {
  header: "Поддержка разработки Vuetify",
  headerText:
    'Vuetify - проект с открытым исходным кодом под лицензией MIT. Это всегда можно будет свободно использовать. Тем не менее, объем усилий, необходимых для поддержания базовой структуры и всех дополняющих пакетов, довольно значителен. С целью продолжения разработки дополнительной функциональности даже после завершения спецификации Material Design вы можете помочь <a href="https://www.patreon.com/vuetify" target="_blank" rel="noreferrer"> поддержка Vuetify в Patreon </a>.',
  backersHeader: "Patreon backers:",
  backersSubHeader: "Diamond ($500/mo)",
  backersSubHeader2: "Palladium ($250/mo)",
  backersSubHeader3: "Platinum ($100/mo)",
  backersSubHeader4: "Gold ($50/mo)",
  affiliatesHeader: "Affiliates:",
  sponsorsHeader: "Service sponsors:",
  questionHeader: "Дополнительные вопросы?",
  questionButton: "Свяжитесь с нами"
}
